import axios from "axios";
import {GET_DEVICE_URL} from "../../../../ServiceUrls";
import {ADD_DEVICE_URL} from "../../../../ServiceUrls";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";



export const ME_URL = "api/me";


export function getDeviceById(deviceId) {
  return axios.get(`${GET_DEVICE_URL}/${deviceId}`);
}


export function deleteDevice(deviceId) {
  //console('dele',deviceId);
  return axios.delete(`${GET_DEVICE_URL}/${deviceId}`);
}

export function getUserByToken() {
  return axios.get(ME_URL);
}
export function get_all_devices() {

  return axios.get(GET_DEVICE_URL);
  // return fetch(GET_DEVICE_URL);
}

export function addDevice(data) {
  //console.log('data', data);
  return axios.post(ADD_DEVICE_URL, data)

}

export function editDevice(data, deviceId) {
  return axios.put(`${GET_DEVICE_URL}/${deviceId}`, data);
}

