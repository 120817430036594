/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link, Switch, Redirect } from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as sensor from "../_redux/sensorsRedux";
import { get_sensors, get_all_sensor, add_sensor, getSensorById } from "../_redux/sensorsCrud";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SensorTableRow from "./SensorTableRow";
// import { makeStylSensorTableRowes } from '@material-ui/core/styles';
import {
  Dialog, AppBar, Toolbar, IconButton, Typography, Button, List, ListItem,
  ListItemText, Divider, Slide, TextField,
  Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import $ from 'jquery';
import SensorForm from "./SensorForm";
var Axisspantext,spantext, stylet,  styletr;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SensorListPage(props) {

  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isFirst, setIsFirst] = React.useState(true);
  const [currentData, setCurrentData] = React.useState([]);
  const [updateMode, setUpdateMode] = React.useState(false);

  const handleClickOpen = () => {
    setCurrentData([])
    setUpdateMode(false);
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
    setOpen(false);
  };
  const showDetial = (d) =>{

    getSensorById(d.id).then(res => {
    //  console.log("details Sensor:", res.data)
      setCurrentData(res.data)
      setUpdateMode(true);
      setOpen(true)


    })

  }
  const [collapsedHeader, setCollapsedHeader] = useState(true);
  let { state } = useSelector(
    (state) => ({
      state: state.sensors
    }),
    shallowEqual
  );
  if (state === undefined) state = {};
  let history = useHistory();
  if (isFirst) {
    setIsFirst(false);
    if (state.is_sensors_loaded == 0 || isFirst) {
     // console.log("sensorPage props", props);

      get_all_sensor().then(res => {
        
        props.setSensors(res.data)
        console.log("sensorPage is_sensors_loaded", res.data);

      })
    }
  }

  return (
    <>
      <div className="table-responsive" style={{ backgroundColor: "white" }}>
        <button type="button" onClick={handleClickOpen} class="btn btn-outline-primary">Add New +</button>
        <table className="table table-borderless table-striped table-vertical-center table-light table-hover">
          <thead class="thead-dark">
            <tr>
              <th scope="col" className="p-0" style={{ width: "30px" }}>  </th>
              
              <th scope="col" className="p-0" style={{ minWidth: "150px" }}  > Device Name</th>
              <th scope="col" className="p-0" style={{ minWidth: "30px" }} > Sensor ID</th>
              <th scope="col" className="p-0" style={{ minWidth: "150px" }} > Sensor Name</th>
              <th scope="col" className="p-0" style={{ minWidth: "50px" }} > Sencor Mac Address</th>
           
              <th scope="col" className="p-0" style={{ minWidth: "50px" }} > Time Delay</th>
              <th scope="col" className="p-0" style={{ minWidth: "30px" }} > Initial State</th>
              <th scope="col" className="p-0" style={{ minWidth: "30px" }} > Linked To Sensor</th>
              <th scope="col" className="p-0" style={{ minWidth: "60px" }} > X - Axis</th>
              <th scope="col" className="p-0" style={{ minWidth: "60px" }} > Y - Axis</th>
              <th scope="col" className="p-0" style={{ minWidth: "60px" }} > Z - Axis</th>
              <th scope="col" className="p-0" style={{ minWidth: "60px" }} > Details</th>
              <th scope="col" className="p-0" style={{ minWidth: "60px" }} > Status</th>
            </tr>
          </thead>
          <tbody>
            
            {state.sensors != null ? state.sensors.map(d => {

              //<SensorCard id={d.id} title={d.sensor_name} description={d.description} img={d.img} showDetial={() => showDetial(d)} />

               Axisspantext = "label label-lg label-light-primary label-inline"
              // if (d.predict_health_value >= 70) {
              //   spantext = "label label-lg  label-inline"
              //   stylet = {
              //     color: 'green',
              //     backgroundColor: 'limegreen'
              //   }
              //   status = "Good"
              // }
              // else {
              //   if (d.predict_health_value < 70 && d.predict_health_value >= 50) {
              //     spantext = "label label-lg label-light-warning label-inline"
              //     stylet = {
              //       // color: 'orange',
              //       // backgroundColor: '#ffeecc'                  
              //     }
              //     status = "Warning"
              //   }
              //   else {
              //     if (d.predict_health_value < 50) {
              //       spantext = "label label-lg label-light-danger label-inline"
              //       stylet = {
              //         // color: 'orange',
              //         // backgroundColor: '#ffeecc'                  
              //       }
              //       status = "Critical"
              //     }
              //   }
              //
              return (

                    <SensorTableRow id={d.id} predict_health_value={d.predict_health_value} sensor_name={d.sensor_name} device_name={d.name} description={d.description} macaddress={d.macaddress} timedelay={d.timedelay} initiateSensor={d.initiateSensor} linkedtosensor={d.linkedtosensor}  showDetial={() => showDetial(d)} />
              );
            }) :
              ' '
            }


          </tbody>
        </table>
      </div>
{/* 
    
      <div className="d-flex flex-column flex-root">
     
        <ul className="dcards">


          <li className="addLi">
            <div className="addNewDev">
              <h1 className="addDev" onClick={handleClickOpen}>
                Add New +
                </h1>
            </div>
          </li>
        </ul>
      </div> */}

      <Dialog fullWidth="true" maxWidth="lg" scroll="body" open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className="appbar">
          <Toolbar>
            <IconButton edge="start" className="appbarText" color="inherit" onClick={handleClose} aria-label="close">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SensorForm handleClose={handleClose} currentData={currentData} updateMode={updateMode} />

      </Dialog>
    </>
  );
}
export default injectIntl(connect(null, sensor.actions)(SensorListPage));
