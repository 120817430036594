import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./gatewayCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  GatewaysLoaded: "[gateway] GatewaysLoaded  Action",
  AddGateway: "[gateway] AddGateway Action",
  HandleVar: "[gateway] HandleVar Action"


};

const initialState = {
  user: undefined,
  authToken: undefined,
  gateways: [],
  is_gateways_loaded: 0,
  name: '',
  url: '',
  serial_number: '',
  ip: '',
  sim_number: '',
  location: '',
  connection_type: '',
  connection_user: '',
  connection_password: '',
  wifi_name: '',
  model: '',
  install_date: '',
  port_count: ''

};

export const reducer = persistReducer(
  { storage, key: "v705-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionTypes.HandleVar: {

        switch (action.name) {
          case 'name':
            //console.log("name :::::", action.value)
            return {
              ...state,
              name: action.value,
            };
          case 'url':
            return {
              ...state,
              url: action.value,
            };

          case 'serial_number':
            return {
              ...state,
              serial_number: action.value,
            };

          case 'ip':
            return {
              ...state,
              ip: action.value,
            };

          case 'sim_number':
            return {
              ...state,
              sim_number: action.value,
            };

          case 'location':
            return {
              ...state,
              location: action.value,
            };
          case 'connection_type':
            return {
              ...state,
              connection_type: action.value,
            };
          case 'connection_user':
            return {
              ...state,
              connection_user: action.value,
            };
          case 'connection_password':
            return {
              ...state,
              connection_password: action.value,
            };
          case 'wifi_name':
            return {
              ...state,
              wifi_name: action.value,
            };
          case 'model':
            return {
              ...state,
              model: action.value,
            };
          case 'install_date':
            return {
              ...state,
              install_date: action.value,
            };
          case 'port_count':
            return {
              ...state,
              port_count: action.value,
            };


        }
      }

      case actionTypes.GatewaysLoaded: {
        return { ...state, is_gateways_loaded: 2, gateways: action.payload };
      }


      case actionTypes.AddGateway: {
        //console.log("payload :::", action.payload)

        return {
          ...state,
          gateways: initialState.gateways.concat(action.payload)
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {

  setGateways: (items) => ({ type: actionTypes.GatewaysLoaded, payload: items }),
  addNewGateway: (item) => ({ type: actionTypes.AddGateway, payload: item }),
  handleVar: (name, value) => ({ type: actionTypes.HandleVar, payload: { name, value } }),

};


