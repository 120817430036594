import axios from "axios";
import {GET_Notication_URL} from "../../../../ServiceUrls";
import {ADD_Notication_URL} from "../../../../ServiceUrls";
import {GET_SENSOR_URL} from "../../../../ServiceUrls";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";


export const ME_URL = "api/me";


export function getNoticationBySensorId(sensorId) {
  return axios.get(`${GET_Notication_URL}/not/${sensorId}`);
}


export function deleteNotication(sensorId) {
  return axios.delete(`${GET_Notication_URL}/${sensorId}`);
}

export function getUserByToken() {
  return axios.get(ME_URL);
}
export function get_all_Notification() {

  return axios.get(`${GET_Notication_URL}`);
  // return fetch(GET_Notication_URL);
}
export function get_range_Notification(startindex,endindex) {

  return axios.get(`${GET_Notication_URL}/${startindex}/${endindex}`);
  // return fetch(GET_Notication_URL);
}
export function get_all_Notification_SensorId(SensorId,startindex,endindex) {

  return axios.get(`${GET_Notication_URL}/${SensorId}/${startindex}/${endindex}`);
  // return fetch(GET_Notication_URL);
}
export function addNotication(data) {
 // console.log('ADD_Notication_URL', data);
  return axios.post(ADD_Notication_URL, data)

}

export function editNotication(data, SensorId) {
  return axios.put(`${GET_Notication_URL}/${SensorId}`, data);
}



