/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { connect, shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as sensor from "../_redux/sensorsRedux";
import { get_Sensors, get_all_sensor, add_sensor, editSensor, deleteSensor } from "../_redux/sensorsCrud";
import { Form, InputGroup, Col, Row } from "react-bootstrap";

import {
    Dialog, AppBar, Toolbar, IconButton, Typography, Button, List, ListItem,
    ListItemText, Divider, Slide, TextField,
    Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core'

function SensorForm(props) {
    const { user } = useSelector(state => state.auth);
    const { handleClose, currentData, updateMode } = props;
    const [lpf2, setlpf2] = React.useState();
    const [filterselection, setFilterSelection] = React.useState();
    const [selftest, setSelftest] = React.useState();
    const [axis, setAxis] = React.useState();
    const [fifo, setFifo] = React.useState();
    const [lpf2_xl_en, setLpf2_XlEn] = React.useState();
    const [accelerometer, setaccelerometer] = React.useState();
    const [sensor_name, setSensorName] = React.useState();
    const [sensor_password, setSensorPassword] = React.useState();
    const [wifi_name, setWifiName] = React.useState();
    const [wifi_password, setWifiPassword] = React.useState();
    const [wifi_name2, setWifiName2] = React.useState();
    const [wifi_password2, setWifiPassword2] = React.useState();
    const [wifi_name3, setWifiName3] = React.useState();
    const [wifi_password3, setWifiPassword3] = React.useState();
    const [wifi_name1, setWifiName1] = React.useState();
    const [wifi_password1, setWifiPassword1] = React.useState();
    const [rssi_threshold, setrssi_threshold] = React.useState();
    const [domain_name, setDomainName] = React.useState();
    const [get_data_link, setGetDataLink] = React.useState();
    const [timedelay, setTimeDelay] = React.useState();
    const [deepsleep, setDeepSleep] = React.useState();
    const [isdel, setIsDel] = React.useState();
    const [numberofdata, setNumberOfData] = React.useState();
    const [power, setPower] = React.useState(100);
    const [continuousdata, setContinuousData] = React.useState();
    const [update_url, setUpdateUrl] = React.useState();
    const [update_servername, setUpdateServername] = React.useState();
    const [initiateSensor, setInitiateSensor] = React.useState();
    const [update_available, setUpdateAvailable] = React.useState();
    const [gateway_id, setGatewayId] = React.useState();
    const [device_id, setDeviceId] = React.useState();
    const [user_id, setUserId] = React.useState();
    const [tempData, setTempData] = React.useState(currentData)
    const [tempretaure_caliber, settempretaure_caliber] = React.useState();
    const [battery_caliber, setbattery_caliber] = React.useState();
    const [battery_min, setbattery_min] = React.useState();
    const [tempretaure_max, settempretaure_max] = React.useState();
    const [vibration_max_x, setvibration_max_x] = React.useState();
    const [vibration_max_y, setvibration_max_y] = React.useState();
    const [vibration_max_z, setvibration_max_z] = React.useState();
    const [lpf2Status, setlpf2Status] = React.useState(false);
    const [filterselectionStatus, setFilterSelectionStatus] = React.useState(false);
    const [selftestStatus, setSelftestStatus] = React.useState(false);
    const [axisStatus, setAxisStatus] = React.useState(false);
    const [fifoStatus, setFifoStatus] = React.useState(false);
    const [lpf2_xl_enStatus, setLpf2_XlEnStatus] = React.useState(false);
    const [accelerometerStatus, setaccelerometerStatus] = React.useState(false);
    const [sensor_nameStatus, setSensorNameStatus] = React.useState(false);
    const [sensor_passwordStatus, setSensorPasswordStatus] = React.useState(false);
    const [wifi_nameStatus, setWifiNameStatus] = React.useState(false);
    const [wifi_passwordStatus, setWifiPasswordStatus] = React.useState(false);
    const [domain_nameStatus, setDomainNameStatus] = React.useState(false);
    const [get_data_linkStatus, setGetDataLinkStatus] = React.useState(false);
    const [timedelayStatus, setTimeDelayStatus] = React.useState(false);
    const [deepsleepStatus, setDeepSleepStatus] = React.useState(false);
    const [isdelStatus, setIsDelStatus] = React.useState(false);
    const [numberofdataStatus, setNumberOfDataStatus] = React.useState(false);
    const [powerStatus, setPowerStatus] = React.useState(false);
    const [continuousdataStatus, setContinuousDataStatus] = React.useState(false);
    const [update_urlStatus, setUpdateUrlStatus] = React.useState(false);
    const [update_servernameStatus, setUpdateServernameStatus] = React.useState(false);
    const [initiateSensorStatus, setInitiateSensorStatus] = React.useState(false);
    const [update_availableStatus, setUpdateAvailableStatus] = React.useState(false);
    const [gateway_idStatus, setGatewayIdStatus] = React.useState(false);
    const [device_idStatus, setDeviceIdStatus] = React.useState(false);

    // function getInitialState() {
    //     console.log('Component Will MOUNT!') ;
    //     if (tempData.initiateSensor == 1) {
    //         isInitSensorTrue()
    //     } else {
    //         isInitSensorFalse()
    //     }

    //     if (tempData.update_available == 1) {
    //         isUpdateTrue()
    //     } else {
    //         isUpdateFalse()
    //     }
    // }

    function onSubmit(params) {
        let Data = {
            title: sensor_name,
            img: 'dcard__image--flowers',
            lpf2: lpf2,
            filterselection: filterselection,//
            selftest: selftest,//
            axis: axis,//
            fifo: fifo,//
            lpf2_xl_en: lpf2_xl_en,
            accelerometer: accelerometer,
            sensor_name: sensor_name,//
            sensor_password: sensor_password,//
            wifi_name: wifi_name,//
            wifi_password: wifi_password,//
            wifi_name2: wifi_name2,//
            wifi_password2: wifi_password2,//
            wifi_name3: wifi_name3,//
            wifi_password3: wifi_password3,//
            wifi_name1: wifi_name1,//
            wifi_password1: wifi_password1,//    
            rssi_threshold: rssi_threshold,//                                  
            domain_name: domain_name,//
            get_data_link: get_data_link,//
            timedelay: timedelay,//
            deepsleep: deepsleep,//
            isdel: isdel,//
            numberofdata: numberofdata,//
            power: power,//
            continuousdata: continuousdata,//
            update_url: update_url,//
            update_servername: update_servername,//
            initiateSensor: initiateSensor,//
            update_available: update_available,//
            gateway_id: props.parent == 'gateway' ? props.parent_id : "",
            device_id: props.parent == 'device' ? props.parent_id : "",
            user_id: user_id,
            tempretaure_caliber: tempretaure_caliber,
            battery_caliber: battery_caliber,
            tempretaure_max: tempretaure_max,
            battery_min: battery_min,
            vibration_max_x: vibration_max_x,
            vibration_max_y: vibration_max_y,
            vibration_max_z: vibration_max_z
        }


        //    console.log(Data)

        // UNCOMMENT THIS TO SEND DATA TO API 
        add_sensor(Data).then(res => {
            get_all_sensor().then(res => {
                props.setSensors(res.data)
            })
            handleClose();

        })


    }

    function onDelete(params) {

        deleteSensor(tempData.id).then(res => {
            get_all_sensor().then(res => {
                props.setSensors(res.data)
                handleClose();

            })
        })
    }

    function onSubmitEdit(params) {



        let Data = {
            id: tempData.id,
            title: tempData.sensor_name,
            img: 'dcard__image--flowers',
            lpf2: lpf2,
            filterselection: filterselection,
            selftest: selftest,
            axis: axis,
            fifo: fifo,
            lpf2_xl_en: lpf2_xl_en,
            accelerometer: accelerometer,
            sensor_name: sensor_name,
            sensor_password: sensor_password,
            wifi_name: wifi_name,
            wifi_password: wifi_password,
            wifi_name: wifi_name,//
            wifi_password: wifi_password,//
            wifi_name2: wifi_name2,//
            wifi_password2: wifi_password2,//
            wifi_name3: wifi_name3,//
            wifi_password3: wifi_password3,//
            wifi_name1: wifi_name1,//
            wifi_password1: wifi_password1,//    
            rssi_threshold: rssi_threshold,//            
            domain_name: domain_name,
            get_data_link: get_data_link,
            timedelay: timedelay,
            deepsleep: deepsleep,
            isdel: isdel,
            numberofdata: numberofdata,
            power: power,
            continuousdata: continuousdata,
            update_url: update_url,
            update_servername: update_servername,
            initiateSensor: initiateSensor,
            update_available: update_available,
            user_id: user_id,
            tempretaure_caliber: tempretaure_caliber,
            battery_caliber: battery_caliber,
            tempretaure_max: tempretaure_max,
            battery_min: battery_min,
            vibration_max_x: vibration_max_x,
            vibration_max_y: vibration_max_y,
            vibration_max_z: vibration_max_z
        }


        //      console.log(Data)




        editSensor(Data, Data.id).then(res => {
            get_all_sensor().then(res => {
                props.setSensors(res.data);
                handleClose();

            })

        })


    }

    function setNewSensorParam() {
        setlpf2(8);
        setFilterSelection(1);
        setSelftest(0);
        setAxis(0);
        setFifo(2);
        setLpf2_XlEn(0);
        setaccelerometer(3);
        setDomainName('www.monosens.com');
        setGetDataLink('http://www.monosens.com/getdata/recivedata.php?value=monosens666');
        setTimeDelay(60);
        setDeepSleep(1);
        setNumberOfData(8);
        setPower(100);
        setIsDel(0);
        setContinuousData(0);
        setUpdateUrl('http://monosens.com/setting/Monosense.ino.nodemcu.bin');
        setUpdateServername('http://monosens.com/setting/Monosense.ino.nodemcu.bin');
        setInitiateSensor(1);
        setUpdateAvailable(0);
        settempretaure_caliber(0);
        // setrssi_threshold(-90);
        setbattery_min(0.1);
        settempretaure_max(80);
        setvibration_max_x(100);
        setvibration_max_y(100);
        setvibration_max_z(100);
        setbattery_caliber(0);

    }

    function handleChange(val) {
        var temp = tempData;

        switch (val.target.name) {
            case 'lpf2':
                temp.lpf2 = (val.target.value)
                setlpf2(temp.lpf2)
                break;
            case 'filterselection':
                if (val.target.value == 1) {
                    isFilterTrue()
                } else {
                    isFilterFalse()
                }
                temp.filterselection = (val.target.value)

                setFilterSelection(temp.filterselection)
                break;
            case 'selftest':
                if (val.target.value == 0) {
                    isSelfTest0()
                } else {
                    isSelfTest1Or2()
                }
                temp.selftest = (val.target.value)

                setSelftest(temp.selftest)
                break;
            case 'axis':
                temp.axis = (val.target.value)

                setAxis(temp.axis)
                break;
            case 'fifo':
                temp.fifo = (val.target.value)

                setFifo(temp.fifo)
                break;
            case 'lpf2_xl_en':
                if (val.target.value == 1) {
                    isLowFilterYes()
                } else {
                    isLowFilterNo()
                }
                temp.lpf2_xl_en = (val.target.value)

                setLpf2_XlEn(temp.lpf2_xl_en)
                break;
            case 'accelerometer':
                temp.accelerometer = (val.target.value)

                setaccelerometer(temp.accelerometer)
                break;
            case 'sensor_name':
                temp.sensor_name = (val.target.value)

                setSensorName(temp.sensor_name)
                //  setNewSensorParam()

                break;
            case 'sensor_password':
                temp.sensor_password = (val.target.value)

                setSensorPassword(temp.sensor_password)
                break;
            case 'wifi_name':
                temp.wifi_name = (val.target.value)

                setWifiName(temp.wifi_name)
                break;
            case 'wifi_name1':
                temp.wifi_name1 = (val.target.value)

                setWifiName1(temp.wifi_name1)
                break;
            case 'wifi_name2':
                temp.wifi_name2 = (val.target.value)

                setWifiName2(temp.wifi_name2)
                break;
            case 'wifi_name3':
                temp.wifi_name3 = (val.target.value)

                setWifiName3(temp.wifi_name3)
                break;
            case 'rssi_threshold':
                temp.rssi_threshold = (val.target.value)

                setrssi_threshold(temp.rssi_threshold)
                break;
            case 'tempretaure_caliber':
                temp.tempretaure_caliber = (val.target.value)

                settempretaure_caliber(temp.tempretaure_caliber)
                break;
            case 'battery_caliber':
                temp.battery_caliber = (val.target.value)

                setbattery_caliber(temp.battery_caliber)
                break;

            case 'tempretaure_max':
                temp.tempretaure_max = (val.target.value)

                settempretaure_max(temp.tempretaure_max)
                break;
            case 'battery_min':
                temp.battery_min = (val.target.value)

                setbattery_min(temp.battery_min)
                break;
            case 'vibration_max_x':
                temp.vibration_max_x = (val.target.value)

                setvibration_max_x(temp.vibration_max_x)
                break;
            case 'vibration_max_y':
                temp.vibration_max_y = (val.target.value)

                setvibration_max_y(temp.vibration_max_y)
                break;
            case 'vibration_max_z':
                temp.vibration_max_z = (val.target.value)

                setvibration_max_z(temp.vibration_max_z)
                break;
            case 'wifi_password':
                temp.wifi_password = (val.target.value)

                setWifiPassword(temp.wifi_password)
                break;
            case 'wifi_password1':
                temp.wifi_password1 = (val.target.value)

                setWifiPassword1(temp.wifi_password1)
                break;
            case 'wifi_password2':
                temp.wifi_password2 = (val.target.value)

                setWifiPassword2(temp.wifi_password2)
                break;
            case 'wifi_password3':
                temp.wifi_password3 = (val.target.value)

                setWifiPassword3(temp.wifi_password3)
                break;

            case 'domain_name':
                temp.domain_name = (val.target.value)
                setDomainName(temp.domain_name)
                break;
            case 'get_data_link':
                temp.get_data_link = (val.target.value)

                setGetDataLink(temp.get_data_link)
                break;
            case 'timedelay':
                temp.timedelay = (val.target.value)
                setTimeDelay(temp.timedelay)
                break;
            case 'deepsleep':
                if (val.target.value == 1) {
                    isDeepSleepTrue()
                } else {
                    isDeepSleepFalse()
                }
                temp.deepsleep = (val.target.value)
                setDeepSleep(temp.deepsleep)
                break;
            case 'isdel':
                temp.isdel = (val.target.value)
                setIsDel(temp.isdel)
                break;
            case 'numberofdata':
                temp.numberofdata = (val.target.value)
                setNumberOfData(temp.numberofdata)
                break;
            case 'power':
                temp.power = (val.target.value)
                setPower(temp.power)
                break;

            case 'continuousdata':
                temp.continuousdata = (val.target.value)

                setContinuousData(temp.continuousdata)
                break;

            case 'update_url':
                temp.update_url = (val.target.value)

                setUpdateUrl(temp.update_url)
                break;

            case 'update_servername':
                temp.update_servername = (val.target.value)

                setUpdateServername(temp.update_servername)
                break;

            case 'initiateSensor':
                if (val.target.value == 1) {
                    isInitSensorTrue()
                } else {
                    isInitSensorFalse()
                }
                temp.initiateSensor = (val.target.value)

                setInitiateSensor(temp.initiateSensor)
                break;
            case 'update_available':
                if (val.target.value == 1) {
                    isUpdateTrue()
                } else {
                    isUpdateFalse()
                }
                temp.update_available = (val.target.value)

                setUpdateAvailable(temp.update_available)
                break;
            case 'gateway_id':
                temp.gateway_id = (val.target.value)

                setGatewayId(temp.gateway_id)
                break;
            case 'device_id':
                temp.device_id = (val.target.value)

                setDeviceId(temp.device_id)
                break;
            case 'user_id':
                temp.user_id = (val.target.value)

                setUserId(temp.user_id)
                break;
            default:
                break;
        }
    }



    function isUpdateTrue() {
        setUpdateServernameStatus(false);
        setUpdateUrlStatus(false);
        setlpf2Status(true);
        setlpf2();
        setLpf2_XlEnStatus(true);
        setaccelerometer();
        setaccelerometerStatus(true);
        setTimeDelayStatus(true);
        setTimeDelay();
        setFilterSelectionStatus(true);
        setFilterSelection();
        setSelftestStatus(true);
        setSelftest();
        setAxisStatus(true);
        setAxis();
        setFifoStatus(true);
        setFifo();
        setDeepSleepStatus(true);
        setDeepSleep();
        setNumberOfDataStatus(true);
        setNumberOfData();
        setPowerStatus(true);

        setContinuousDataStatus(true);
        setContinuousData();
        setInitiateSensorStatus(true);
        setInitiateSensor();


    }
    function isUpdateFalse() {

        setUpdateServername();

        setUpdateUrl();
        setlpf2Status(true);
        setaccelerometerStatus(true);
        setLpf2_XlEnStatus(true);
        setFilterSelectionStatus(true);
        setSelftestStatus(true);
        setAxisStatus(true);
        setFifoStatus(true);
        setDeepSleepStatus(true);
        setNumberOfDataStatus(true);
        setPowerStatus(true);
        setContinuousDataStatus(true);
        setTimeDelayStatus(true);
        setInitiateSensorStatus(false);


    }
    function isInitSensorTrue() {

        setUpdateServername();

        setUpdateUrl();
        setlpf2Status(true);
        setlpf2();
        setLpf2_XlEnStatus(true);
        setaccelerometerStatus(true);
        setaccelerometer();
        setTimeDelayStatus(true);
        setTimeDelay();
        setFilterSelectionStatus(true);
        setFilterSelection();
        setSelftestStatus(true);
        setSelftest();
        setAxisStatus(true);
        setAxis()
        setFifoStatus(true);
        setFifo();
        setDeepSleepStatus(true);
        setDeepSleep();
        setNumberOfDataStatus(true);
        setNumberOfData();
        setPowerStatus(true);

        setContinuousDataStatus(true);
        setContinuousData();

    }
    function isInitSensorFalse() {
        setlpf2Status(true);
        setLpf2_XlEnStatus(true);
        setaccelerometerStatus(true);
        setTimeDelayStatus(true);
        setFilterSelectionStatus(true);
        setSelftestStatus(true);
        setAxisStatus(true);
        setFifoStatus(true);
        setDeepSleepStatus(false);
        setNumberOfDataStatus(true);
        setPowerStatus(false);

        setContinuousDataStatus(true);



    }

    function isDeepSleepTrue() {
        setlpf2Status(true);
        setLpf2_XlEnStatus(true);
        setTimeDelayStatus(false);
        setFilterSelectionStatus(true);
        setSelftestStatus(false);
        setAxisStatus(true);
        setFifoStatus(true);
        setaccelerometerStatus(true);
        setNumberOfDataStatus(true);
        setPowerStatus(true);
        setContinuousDataStatus(true);
        setContinuousData();



    }
    function isDeepSleepFalse() {
        setaccelerometerStatus(true);
        setlpf2Status(true);
        setLpf2_XlEnStatus(true);
        setTimeDelayStatus(true);
        setTimeDelay();
        setFilterSelectionStatus(true);
        setSelftestStatus(false);
        setAxisStatus(true);
        setFifoStatus(true);

        setNumberOfDataStatus(true);
        setPowerStatus(true);
        setContinuousDataStatus(true);

    }

    function isSelfTest1Or2() {
        setlpf2Status(true);
        setlpf2();
        setLpf2_XlEnStatus(true);
        setaccelerometer();
        setaccelerometerStatus(true);
        setTimeDelayStatus(true);
        setTimeDelay();
        setFilterSelectionStatus(true);
        setFilterSelection();
        setaccelerometerStatus(true);
        setAxisStatus(true);
        setAxis();
        setFifoStatus(true);
        setFifo();

        setNumberOfDataStatus(true);
        setNumberOfData();
        setPowerStatus(true);
        setContinuousDataStatus(true);

    }
    function isSelfTest0() {
        setaccelerometerStatus(true);
        setlpf2Status(true);
        setLpf2_XlEnStatus(true);
        setTimeDelayStatus(true);
        setFilterSelectionStatus(false);
        setaccelerometerStatus(true);
        setAxisStatus(true);
        setFifoStatus(true);

        setNumberOfDataStatus(false);
        setPowerStatus(true);
        setContinuousDataStatus(true);

    }
    function isFilterTrue() {
        setlpf2Status(false);

        setAxisStatus(false);
        setaccelerometerStatus(false);



        setLpf2_XlEnStatus(true)

    }
    function isFilterFalse() {
        setlpf2Status(true);
        setLpf2_XlEnStatus(false);
        setAxisStatus(true);
        setaccelerometerStatus(true);

    }
    function isLowFilterYes() {
        setlpf2Status(false);

        setAxisStatus(false);
        setaccelerometerStatus(false);

    }
    function isLowFilterNo() {
        setlpf2Status(true);
        setlpf2(8);
        setAxisStatus(false);
        setaccelerometerStatus(false);

    }


    return (
        <>



            <List >
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <ListItem  >

                    <InputLabel style={{ width: '10%' }}>Sensor Name</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="sensor_name"
                        id="outlined-helperText"
                        //label="Sensor Name"
                        variant="outlined"
                        //defaultValue="Success"
                        required
                        size="max"
                        value={tempData.sensor_name}
                        style={{ width: '40%' }}
                    />
                    <InputLabel style={{ width: '10%' }}>Sensor Password</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="sensor_password"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.sensor_password}
                        style={{ width: '40%' }}

                    />
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '16.5%' }}>Temperature adjustment</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="tempretaure_caliber"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.tempretaure_caliber}
                        style={{ width: '6%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '16.5%' }}>Battery adjustment</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="battery_caliber"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.battery_caliber}
                        style={{ width: '6%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '16.5%' }}>Rssi threshold</InputLabel>
                    <TextField
                        onChange={handleChange}
                        inputProps={{ min: "-100", max: "0", step: "1" }}
                        name="rssi_threshold"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.rssi_threshold}
                        style={{ width: '6%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '16.5%' }}>Battery MIN (Alarm)</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="battery_min"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.battery_min}
                        style={{ width: '6%' }}
                        type="number"
                    />                    
                </ListItem>

                <ListItem>
                    <InputLabel style={{ width: '16.5%' }}>Vibration RMS MAX Alarm-X (G) </InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="vibration_max_x"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.vibration_max_x}
                        style={{ width: '6%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '16.5%' }}>Vibration RMS MAX Alarm-Y (G) </InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="vibration_max_y"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.vibration_max_y}
                        style={{ width: '6%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '16.5%' }}>Vibration RMS MAX Alarm-Z (G)</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="vibration_max_z"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.vibration_max_z}
                        style={{ width: '6%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '16.5%' }}>Tempretaure MAX Alarm (°C)</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="tempretaure_max"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.tempretaure_max}
                        style={{ width: '6%' }}
                        type="number"
                    />


                </ListItem>
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Wifi Name No.1</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_name"
                        id="outlined-helperText"
                        // label="Wifi Name"
                        variant="outlined"
                        value={tempData.wifi_name}
                        style={{ width: '40%' }}
                    />

                    <InputLabel style={{ width: '10%' }}>Wifi Password No.1</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_password"
                        id="outlined-helperText"
                        label="Wifi Password"
                        variant="outlined"
                        value={tempData.wifi_password}
                        style={{ width: '40%' }}

                    />
                </ListItem>
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Wifi Name No.2</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_name1"
                        id="outlined-helperText"
                        // label="Wifi Name"
                        variant="outlined"
                        value={tempData.wifi_name1}
                        style={{ width: '40%' }}
                    />

                    <InputLabel style={{ width: '10%' }}>Wifi Password No.2</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_password1"
                        id="outlined-helperText"
                        //  label="Wifi Password"
                        variant="outlined"
                        value={tempData.wifi_password1}
                        style={{ width: '40%' }}

                    />
                </ListItem>
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Wifi Name No.3</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_name2"
                        id="outlined-helperText"
                        // label="Wifi Name"
                        variant="outlined"
                        value={tempData.wifi_name2}
                        style={{ width: '40%' }}
                    />

                    <InputLabel style={{ width: '10%' }}>Wifi Password No.3</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_password2"
                        id="outlined-helperText"
                        //  label="Wifi Password"
                        variant="outlined"
                        value={tempData.wifi_password2}
                        style={{ width: '40%' }}

                    />
                </ListItem>
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Wifi Name No.4</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_name3"
                        id="outlined-helperText"
                        // label="Wifi Name"
                        variant="outlined"
                        value={tempData.wifi_name3}
                        style={{ width: '40%' }}
                    />

                    <InputLabel style={{ width: '10%' }}>Wifi Password No.4</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_password3"
                        id="outlined-helperText"
                        //   label="Wifi Password"
                        variant="outlined"
                        value={tempData.wifi_password3}
                        style={{ width: '40%' }}

                    />
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '10%' }}>Domain Name</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="domain_name"
                        id="outlined-helperText"
                        label="Domain Name"
                        variant="outlined"
                        value={tempData.domain_name}
                        defaultValue="www.monosens.com"
                        style={{ width: '40%' }}
                    />
                    <InputLabel style={{ width: '10%' }}>Get Data Link</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="get_data_link"
                        id="outlined-helperText"
                        label="Get Data Link"
                        variant="outlined"
                        value={tempData.get_data_link}
                        defaultValue="http://www.monosens.com/getdata/recivedata.php?value=monosens666"
                        style={{ width: '40%' }}
                    />
                </ListItem>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Update Available</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >
                        <Select
                            native
                            value={tempData.update_available}
                            defaultValue={0}
                            style={{ width: '20%' }}
                            onChange={handleChange}

                            inputProps={{
                                name: 'update_available',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}> No</option>
                            <option value={1}> Yes</option>
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '10%' }}>Update Domain</InputLabel>
                    <TextField
                        onChange={handleChange}
                        disabled={update_servernameStatus}
                        name="update_servername"
                        id="outlined-helperText"
                        label="Update Domain"
                        variant="outlined"
                        value={tempData.update_servername}
                        defaultValue="http://monosens.com/setting/Monosense.ino.nodemcu.bin"
                        style={{ width: '40%' }}

                    />
                    <InputLabel style={{ width: '10%' }}>Update Link</InputLabel>
                    <TextField
                        disabled={update_urlStatus}
                        onChange={handleChange}
                        name="update_url"
                        id="outlined-helperText"
                        label="Update Link"
                        variant="outlined"
                        value={tempData.update_url}
                        defaultValue="http://monosens.com/setting/Monosense.ino.nodemcu.bin"
                        style={{ width: '40%', }}

                    />
                </ListItem>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <ListItem>

                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Initiate Sensor</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >
                        <Select
                            native
                            style={{ width: '12%' }}
                            value={tempData.initiateSensor}
                            defaultValue={1}
                            disabled={initiateSensorStatus}

                            onChange={handleChange}

                            inputProps={{
                                name: 'initiateSensor',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" > </option>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Run Deep Sleep</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >
                        <Select
                            disabled={deepsleepStatus}

                            value={tempData.deepsleep}
                            defaultValue={1}
                            native
                            style={{ width: '12%' }}
                            onChange={handleChange}

                            inputProps={{
                                name: 'deepsleep',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </Select>
                    </FormControl>

                </ListItem>

                <ListItem>
                    <InputLabel style={{ width: '10%' }}>Power</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="power"
                        disabled={powerStatus}
                        inputProps={{ min: "0", max: "100", step: "1" }}
                        id="outlined-helperText"
                        label="Power"
                        variant="outlined"
                        value={initiateSensor == 1 ? 100 : tempData.power}
                        defaultValue={100}
                        style={{ width: '40%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '10%' }}>Time Delay</InputLabel>
                    <TextField
                        onChange={handleChange}
                        disabled={timedelayStatus}
                        type="number"
                        name="timedelay"
                        id="outlined-helperText"
                        label="Time Delay"
                        variant="outlined"
                        value={tempData.timedelay}
                        defaultValue={60}
                        style={{ width: '40%' }}
                    />
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Continuous Data</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >
                        <Select
                            value={tempData.continuousdata}
                            defaultValue={0}
                            disabled={continuousdataStatus}

                            native
                            style={{ width: '20%' }}
                            onChange={handleChange}

                            inputProps={{
                                name: 'continuousdata',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </Select>
                    </FormControl>
                </ListItem>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem>
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Self Test</InputLabel>
                    <FormControl style={{ width: '20%' }} variant="outlined" >
                        <Select
                            disabled={selftestStatus}
                            defaultValue={0}
                            value={tempData.selftest}

                            onChange={handleChange}

                            native
                            style={{ width: '100%' }}

                            //onChange={handleChange}

                            inputProps={{
                                name: 'selftest',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>Normal mode</option>
                            <option value={1}>Positive sign self-test</option>
                            <option value={2}>Negative sign self-test</option>

                        </Select>
                    </FormControl>

                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '15%' }} htmlFor="outlined-age-native-simple">FIFO Mode Selection</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >

                        <Select
                            value={tempData.fifo}
                            defaultValue={2}
                            disabled={fifoStatus}

                            onChange={handleChange}

                            native
                            style={{ width: '40٪' }}

                            //onChange={handleChange}
                            label="FIFO mode selection"
                            inputProps={{
                                name: 'fifo',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>Bypass mode</option>
                            <option value={1}>FIFO mode</option>
                            <option value={2}>Continuous-to-FIFO mode</option>
                            <option value={3}>Bypass-to-continuous mode</option>
                            <option value={4}>Continuous mode</option>
                            <option value={5}>Bypass-to-FIFO mode</option>


                        </Select>
                    </FormControl>
                    <InputLabel style={{ width: '15%' }} htmlFor="outlined-age-native-simple">Number Of Data</InputLabel>
                    <TextField
                        inputProps={{ min: "1", max: "8", step: "1" }}
                        onChange={handleChange}
                        disabled={numberofdataStatus}
                        type="number"
                        name="numberofdata"
                        id="outlined-helperText"
                        label="Number Of Data"

                        variant="outlined"
                        value={tempData.numberofdata}
                        defaultValue={8}
                        style={{ width: '40%' }}
                    />

                </ListItem>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem >
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Filter Selection</InputLabel>
                    <FormControl style={{ width: '20%' }} variant="outlined" >

                        <Select
                            native
                            style={{ width: '100%' }}
                            value={tempData.filterselection}
                            defaultValue={1}
                            disabled={filterselectionStatus}

                            onChange={handleChange}

                            inputProps={{
                                name: 'filterselection',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>Low Pass</option>
                            <option value={1}>High Pass</option>
                        </Select>
                    </FormControl>



                </ListItem>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem >
                    <InputLabel style={{ width: '22%' }} htmlFor="outlined-age-native-simple">Lowpass Filter Enable</InputLabel>
                    <FormControl style={{ width: '10%' }} variant="outlined" >

                        <Select
                            native
                            style={{ width: '100%' }}
                            value={tempData.lpf2_xl_en}
                            defaultValue={0}
                            disabled={lpf2_xl_enStatus}

                            onChange={handleChange}

                            inputProps={{
                                name: 'lpf2_xl_en',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </Select>
                    </FormControl>
                </ListItem >
                <ListItem>
                    <InputLabel style={{ width: '22%' }} htmlFor="outlined-age-native-simple">Accelerometer Full-scale Selection</InputLabel>
                    <FormControl style={{ width: '10%' }} variant="outlined" >

                        <Select
                            value={tempData.accelerometer}
                            defaultValue={3}
                            disabled={accelerometerStatus}


                            native
                            style={{ width: '100%' }}
                            onChange={handleChange}

                            inputProps={{
                                name: 'accelerometer',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>2G</option>
                            <option value={1}>4G</option>
                            <option value={2}>8G</option>
                            <option value={3}>16G</option>

                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '22%' }} htmlFor="outlined-age-native-simple">Axis Selection</InputLabel>
                    <FormControl style={{ width: '10%' }} variant="outlined" >

                        <Select
                            value={tempData.axis}
                            defaultValue={0}
                            disabled={axisStatus}

                            onChange={handleChange}

                            native
                            style={{ width: '100%' }}

                            //onChange={handleChange}

                            inputProps={{
                                name: 'axis',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>3 axes (XYZ)</option>
                            <option value={1}>X-axis</option>
                            <option value={2}>Y-axis</option>
                            <option value={3}>Z-axis</option>


                        </Select>
                    </FormControl>
                </ListItem >
                <ListItem>
                    <InputLabel style={{ width: '22%' }} htmlFor="outlined-age-native-simple">Accelerometer Bandwidth Configurations</InputLabel>
                    <FormControl style={{ width: '10%' }} variant="outlined" >

                        <Select
                            value={tempData.lpf2}
                            defaultValue={8}
                            disabled={lpf2Status}

                            onChange={handleChange}

                            native
                            style={{ width: '100%' }}

                            //onChange={handleChange}

                            inputProps={{
                                name: 'lpf2',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>ODR/4</option>
                            <option value={0}>ODR/10</option>
                            <option value={2}>ODR/20</option>
                            <option value={3}>ODR/45</option>
                            <option value={4}>ODR/100</option>
                            <option value={5}>ODR/200</option>
                            <option value={6}>ODR/400</option>
                            <option value={7}>ODR/800</option>
                            <option value={8}>6.3 kHz</option>


                        </Select>
                    </FormControl>


                </ListItem>
                {handleChange}

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                {
                    updateMode ?
                        <div>
                            <button id="sbtn" onClick={onSubmitEdit}></button>
                            {user.role == 'admin' ?
                                <button id="dbtn" onClick={onDelete}></button>
                                : <div></div>}
                        </div>
                        :
                        <div>

                            <button id="sbtn" onClick={onSubmit}></button>
                        </div>




                }




            </List>

        </>
    );
}

export default injectIntl(connect(null, sensor.actions)(SensorForm));
