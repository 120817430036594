// export const GET_SENSOR_URL = 'http://127.0.0.1:8000/api/auth/sensors'
// export const GET_DEVICE_URL = 'http://127.0.0.1:8000/api/auth/devices'
// export const GET_GATEWAY_URL = 'http://127.0.0.1:8000/api/auth/gateways'
// export const ADD_GATEWAY_URL = 'http://127.0.0.1:8000/api/auth/gateways'
// export const ADD_DEVICE_URL = 'http://127.0.0.1:8000/api/auth/devices'
// export const PRODUCTS_URL = "http://127.0.0.1:8000/api/products"
// export const ADD_SENSOR_URL = 'http://127.0.0.1:8000/api/auth/sensors'
// export const GET_USERS_URL = 'http://127.0.0.1:8000/api/auth/allUsers'
// export const LOGIN_URL = "http://127.0.0.1:8000/api/auth/login";
// export const REGISTER_URL = "http://127.0.0.1:8000/api/auth/signup";
// export const ME_URL = "http://127.0.0.1:8000/api/auth/user";
// export const EDIT_PROFILE_URL = 'http://127.0.0.1:8000/api/auth/user'
// export const GET_USERACTIVITY_URL = 'http://127.0.0.1:8000/api/auth/useractivitie'
// export const ADD_USERACTIVITY_URL = 'http://127.0.0.1:8000/api/auth/useractivitie'

// export const GET_Notication_URL = 'http://127.0.0.1:8000/api/auth/notification'
// export const ADD_Notication_URL = 'http://127.0.0.1:8000/api/auth/notification'


export const LOGIN_URL = "https://api.monosens.com/api/auth/login";
export const REGISTER_URL = "https://api.monosens.com/api/auth/signup";
export const ME_URL = "https://api.monosens.com/api/auth/user";
export const GET_DEVICE_URL = 'https://api.monosens.com/api/auth/devices'
export const GET_SENSOR_URL = 'https://api.monosens.com/api/auth/sensors'
export const EDIT_PROFILE_URL = 'https://api.monosens.com/api/auth/user'
export const ADD_SENSOR_URL = 'https://api.monosens.com/api/auth/sensors'
export const ADD_GATEWAY_URL = 'https://api.monosens.com/api/auth/gateways'
export const GET_GATEWAY_URL = 'https://api.monosens.com/api/auth/gateways'
export const ADD_DEVICE_URL = 'https://api.monosens.com/api/auth/devices'
export const PRODUCTS_URL = "https://api.monosens.com/api/auth/products";
export const GET_USERS_URL = 'https://api.monosens.com/api/auth/allUsers'
export const GET_USERACTIVITY_URL = 'https://api.monosens.com/api/auth/useractivitie'
export const ADD_USERACTIVITY_URL = 'https://api.monosens.com/api/auth/useractivitie'

export const GET_Notication_URL = 'https://api.monosens.com/api/auth/notification'
export const ADD_Notication_URL = 'https://api.monosens.com/api/auth/notification'


// export const LOGIN_URL = "https://api.monosens.ir/api/auth/login";
// export const REGISTER_URL = "https://api.monosens.ir/api/auth/signup";
// export const ME_URL = "https://api.monosens.ir/api/auth/user";
// export const GET_DEVICE_URL = 'https://api.monosens.ir/api/auth/devices'
// export const GET_SENSOR_URL = 'https://api.monosens.ir/api/auth/sensors'
// export const EDIT_PROFILE_URL = 'https://api.monosens.ir/api/auth/user'
// export const ADD_SENSOR_URL = 'https://api.monosens.ir/api/auth/sensors'
// export const ADD_GATEWAY_URL = 'https://api.monosens.ir/api/gateways'
// export const GET_GATEWAY_URL = 'https://api.monosens.ir/api/gateways'
// export const ADD_DEVICE_URL = 'https://api.monosens.ir/api/devices'
// export const PRODUCTS_URL = "https://api.monosens.ir/api/products";
// export const GET_USERS_URL = 'https://api.monosens.ir/api/auth/allUsers'
// export const GET_USERACTIVITY_URL = 'https://api.monosens.ir/api/auth/useractivities'
// export const ADD_USERACTIVITY_URL = 'https://api.monosens.ir/api/auth/useractivities'
