import axios from "axios";
import {GET_USERACTIVITY_URL} from "../../../../ServiceUrls";
import {ADD_USERACTIVITY_URL} from "../../../../ServiceUrls";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";


export const ME_URL = "api/me";


export function getUserActivityById(UserActivityId) {
  return axios.get(`${GET_USERACTIVITY_URL}/${UserActivityId}`);
}


export function deleteActivity(UserActivityId) {
  return axios.delete(`${GET_USERACTIVITY_URL}/${UserActivityId}`);
}

export function getUserByToken() {
  return axios.get(ME_URL);
}

export function get_all_UserActivities() {

  return axios.get(GET_USERACTIVITY_URL);
  // return fetch(GET_USERACTIVITY_URL);
}

export function addActivity(data) {
 // console.log('ADD_USERACTIVITY_URL', data);
  return axios.post(ADD_USERACTIVITY_URL, data)

}

export function editUserActivity(data, UserActivityId) {
  return axios.put(`${GET_USERACTIVITY_URL}/${UserActivityId}`, data);
}

