/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import axios from "axios";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { GET_SENSOR_URL } from "../../../ServiceUrls"
import { GET_DEVICE_URL } from "../../../ServiceUrls"

var sensors = [];
var devices = [];

var spantext, stylet, status, styletr;

function get_all_sensor(parent, parentId) {
  if (parent) {

    return axios.get(`${GET_SENSOR_URL}/${parent}/${parentId}`);

  } else {
    return axios.get(GET_SENSOR_URL);
  }
  // return fetch(GET_SENSOR_URL);
}

function get_all_device(parent, parentId) {
  if (parent) {

    return axios.get(`${GET_DEVICE_URL}/${parent}/${parentId}`);

  } else {
    return axios.get(GET_DEVICE_URL);
  }
  // return fetch(GET_SENSOR_URL);
}




function get_sensor_predict(sensorId) {
  return axios.get(`${GET_SENSOR_URL}/dashboardpredict/${sensorId}`);
}




var options4 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  title: {
    text: 'Health Status'
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
      },
      showInLegend: true
    }
  },
  series: [{
    name: 'Devices',
    colorByPoint: true,
    data: [{
      name: 'Good',
      y: 50,
      color: '#90ed7d',
      sliced: true,
      selected: true
    }, {
      name: 'Critical',
      y: 25,
      color: 'red'
    }, {
      name: 'Warning',
      y: 25,
      color: '#ffcc66'
    }]
  }]
}


export function DashboardAtOneGlance() {
  const [key, setKey] = useState("Month");
  const [collapsedHeader, setCollapsedHeader] = useState(true);
  const [isFirst, setIsFirst] = React.useState(true);
  const [deviceList, setDeviceList] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [sensorList, setSensorList] = React.useState([]);
  const [seriesFFT, setSeriesFFT] = React.useState([]);
  const [seriesHistorical, setSeriesHistorical] = React.useState([]);
  const [seriesPredictFailure, setSeriesPredictFailure] = React.useState([]);
  const [seriesLastdatachart, setSeriesLastdatachart] = React.useState([]);
  const [seriesHistoricalCategory, setSeriesHistoricalCategory] = React.useState([]);

  if (isFirst) {
    setIsFirst(false);
    if (!isLoaded) {
      get_all_sensor().then(res => {
        sensors = res.data;

        console.log("dashboardSensors", res.data);
        if (sensors && sensors.length > 0) {
          for (let k = 0; k < sensors.length; k++) {
           //  console.log("k", k);
          //   console.log("sk", sensors.length);
            get_sensor_predict(sensors[k].id).then(res => {
              //console.log("predict", res.data);
              var sensorId = sensors[k].id;
              var predictFailureChart = [];

              var dataJanuary = [];
              var dataFebruary = [];
              var dataMarch = [];
              var dataApril = [];
              var dataMay = [];
              var dataJune = [];
              var dataJuly = [];
              var dataAugust = [];
              var dataSeptember = [];
              var dataOctober = [];
              var dataNovember = [];
              var dataDecember = [];

              if (res.data.PredictFailure && res.data.PredictFailure.length > 0) {
                for (let i = 0; i < res.data.PredictFailure.length; i++) {
                  const element = res.data.PredictFailure[i];
                  var date = new Date(element.date);
                  if (date.getFullYear() == 2021)
                    if ((date.getMonth() + 1) == 1) {
                      dataJanuary.push(element.value)
                    } else
                      if ((date.getMonth() + 1) == 2) {
                        dataFebruary.push(element.value)
                      } else
                        if ((date.getMonth() + 1) == 3) {
                          dataMarch.push(element.value)
                        } else
                          if ((date.getMonth() + 1) == 4) {
                            dataApril.push(element.value)
                          } else
                            if ((date.getMonth() + 1) == 5) {
                              dataMay.push(element.value)
                            } else
                              if ((date.getMonth() + 1) == 6) {
                                dataJune.push(element.value)
                              } else
                                if ((date.getMonth() + 1) == 7) {
                                  dataJuly.push(element.value)
                                } else
                                  if ((date.getMonth() + 1) == 8) {
                                    dataAugust.push(element.value)
                                  } else
                                    if ((date.getMonth() + 1) == 9) {
                                      dataSeptember.push(element.value)
                                    } else
                                      if ((date.getMonth() + 1) == 10) {
                                        dataOctober.push(element.value)
                                      } else
                                        if ((date.getMonth() + 1) == 11) {
                                          dataNovember.push(element.value)
                                        } else
                                          if ((date.getMonth() + 1) == 12) {
                                            dataDecember.push(element.value)
                                          }
                }
              }
              predictFailureChart = [
                getAverage(dataJanuary), getAverage(dataFebruary),
                getAverage(dataMarch), getAverage(dataApril),
                getAverage(dataMay), getAverage(dataJune),
                getAverage(dataJuly), getAverage(dataAugust),
                getAverage(dataSeptember), getAverage(dataOctober),
                getAverage(dataNovember), getAverage(dataDecember)];

              var temp3 = seriesPredictFailure;
              temp3.push({
                id: sensorId,
                name: res.data.device_name,
                type: 'line',
                data: predictFailureChart // We will set the FFTChartY array here
              });

              if (k ==3)
                setIsLoaded(true);
              setSeriesPredictFailure(temp3);

            })
          }

        }
      })

      get_all_device().then(res => {
        devices = res.data;

        setDeviceList(res.data);
      })
      //console.log("ss", seriesPredictFailure);

    }
  }
  var optionsPredictFailure = {
    chart: {
      type: 'areaspline'
    },
    title: {
      text: 'Predict device failure percentage'
    },

    xAxis: {
      categories: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    },
    yAxis: {
      title: {
        text: 'Failure percentage'
      }
      ,
      plotBands: [{ // visualize the weekend
        from: 40,
        to: 70,
        color: '#ffcc66'//'rgba(1, 1, 255, .2)'
      }, { // visualize the weekend
        from: 70,
        to: 100,
        color: 'red'//'rgba(255, 10, 13, .2)'
      }, { // visualize the weekend
        from: 0,
        to: 50,
        color: '#90ed7d'//'rgba(255, 10, 13, .2)'
      }]
    },
    tooltip: {
      shared: true,
      valueSuffix: ' Percent'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5
      }
    },
    series: seriesPredictFailure
  }
  const getAverage = (monthValues) => {
    var sum = 0;
    var avg = 0;
    if (monthValues && monthValues.length > 0) {
      for (let i = 0; i < monthValues.length; i++) {
        const value = monthValues[i];
        sum = sum + value;
      }
      avg = sum / monthValues.length;
    }
    if (monthValues == 0)
      return null;
    else
      return avg;

  };

  styletr = {
    backgroundColor: 'white',
  }
  //console.log("devices", devices);
  return (
    <div>
      {
        (isLoaded) ?
          <div>
            <div className="row" >
              <div className="col-lg-4">
                <HighchartsReact
                  // containerProps={{ style: { height: "320px" } }}
                  highcharts={Highcharts}
                  options={options4}
                />
              </div>
              <div className="col-lg-8">
                <HighchartsReact
                  // containerProps={{ style: { height: "320px" } }}
                  highcharts={Highcharts}
                  options={optionsPredictFailure}
                  series={seriesPredictFailure}
                />
              </div>
            </div>
            <br></br>
            <div className="row" >
              <div className="col-lg-6">
                {/* Head */}
                <div className="card-header border-0 pt-2 pb-2 text-dark">
                  <h4 class="text-center">
                    <span className="card-label font-weight-bolder text-dark">
                      Sensors Information
                 </span>
                  </h4>
                </div>
                <br></br>
                {/* Body */}
                <div className="card-body pt-3 pb-0 " style={styletr} >
                  <div className="table-responsive" >
                    <table className="table table-borderless table-vertical-center" >
                      <thead>
                        <tr>
                          <th className="p-0" style={{ width: "30px" }} />
                          <th className="p-0" style={{ minWidth: "130px" }} />
                          <th className="p-0" style={{ minWidth: "70px" }} />
                          <th className="p-0" style={{ minWidth: "130px" }} />
                          <th className="p-0" style={{ minWidth: "30px" }} />

                        </tr>
                      </thead>

                      <tbody >
                        {sensors != null ? sensors.map(d => {

                          if (d.predict_health_value >= 70) {
                            spantext = "label label-lg  label-inline"
                            stylet = {
                              color: 'green',
                              backgroundColor: 'limegreen'
                            }
                            status = "Good"
                          }
                          else {
                            if (d.predict_health_value < 70 && d.predict_health_value >= 50) {
                              spantext = "label label-lg label-light-warning label-inline"
                              stylet = {
                                // color: 'orange',
                                // backgroundColor: '#ffeecc'                  
                              }
                              status = "Warning"
                            }
                            else {
                              if (d.predict_health_value < 50) {
                                spantext = "label label-lg label-light-danger label-inline"
                                stylet = {
                                  // color: 'orange',
                                  // backgroundColor: '#ffeecc'                  
                                }
                                status = "Critical"
                              }
                            }
                          }

                          return (

                            <tr >
                              <td className="pl-0 py-4">
                                <div className="symbol symbol-40 symbol-light mr-1">
                                  <span className="symbol-label">
                                    <SVG
                                      src={toAbsoluteUrl("/media/svg/misc/008-infography.svg")}
                                      className="h-50 align-self-center"
                                    ></SVG>
                                  </span>
                                </div>
                              </td>
                              <td className="text-left">
                                <a
                                  href="#"
                                  className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >
                                  {d.sensor_name}
                                </a>
                                <div>
                                  <span className="font-weight-bolder">Power:</span>{" "}
                                  <a
                                    className="text-muted font-weight-bold text-hover-primary"
                                    href="#"
                                  > {d.power}
                                  </a>
                                </div>
                              </td>
                              <td  className="text-right">
                                <span className=" font-weight-bolder d-block font-size-sm">
                                  Gateway:
                                </span>
                                <span className="font-weight-bolder d-block font-size-sm">
                                  Domain :
                                </span>
                              </td>
                              <td className="text-left">
                                <span className="text-muted font-weight-bold d-block">
                                  {d.wifi_name}
                                </span>
                                <span className="text-muted font-weight-bold"> {d.domain_name} </span>
                              </td>
                              <td className="text-right">
                                {/* label-light-warning    label-light-primary  label-light-danger  label-light-success   label-light-info  label-light-light*/}

                                <span style={stylet} className={spantext}>
                                  {status}
                                </span>
                              </td>
                            </tr>
                          );
                        }) :
                          ' '
                        }


                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


              <div className="col-lg-6">
                {/* Head */}
                <div className="card-header border-0 pt-2 pb-2 text-dark">
                  <h4 class="text-center">
                    <span className="card-label font-weight-bolder text-dark">
                      Device Information
          </span>
                  </h4>
                </div>
                <br></br>
                {/* Body */}
                <div className="card-body pt-3 pb-0" style={styletr}>
                  <div className="table-responsive">
                    <table className="table table-borderless table-vertical-center">
                      <thead>
                        <tr>
                          <th className="p-0" style={{ width: "30px" }} />
                          <th className="p-0" style={{ minWidth: "150px" }} />
                          <th className="p-0" style={{ minWidth: "30px" }} />
                          <th className="p-0" style={{ minWidth: "150px" }} />
                          <th className="p-0" style={{ minWidth: "30px" }} />

                        </tr>
                      </thead>
                      <tbody>
                        {devices != null ? devices.map(d => {
                                                    if (d.predict_health_value >= 70) {
                                                      spantext = "label label-lg  label-inline"
                                                      stylet = {
                                                        color: 'green',
                                                        backgroundColor: 'limegreen'
                                                      }
                                                      status = "Good"
                                                    }
                                                    else {
                                                      if (d.predict_health_value < 70 && d.predict_health_value >= 50) {
                                                        spantext = "label label-lg label-light-warning label-inline"
                                                        stylet = {
                                                          // color: 'orange',
                                                          // backgroundColor: '#ffeecc'                  
                                                        }
                                                        status = "Warning"
                                                      }
                                                      else {
                                                        if (d.predict_health_value < 50) {
                                                          spantext = "label label-lg label-light-danger label-inline"
                                                          stylet = {
                                                            // color: 'orange',
                                                            // backgroundColor: '#ffeecc'                  
                                                          }
                                                          status = "Critical"
                                                        }
                                                      }
                                                    }
                          return (

                            <tr>
                              <td className="pl-0 py-4">
                                <div className="symbol symbol-40 symbol-light mr-1">
                                  <span className="symbol-label">
                                    <SVG
                                      src={toAbsoluteUrl("/media/svg/misc/004-retweet.svg")}
                                      className="h-40 align-self-center"
                                    ></SVG>
                                  </span>
                                </div>
                              </td>
                              <td className="pl-0">
                                <a
                                  href="#"
                                  className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >
                                  {d.name}
                                </a>
                                <div>
                                  <span className="font-weight-bolder">Model:</span>{" "}
                                  <a
                                    className="text-muted font-weight-bold text-hover-primary"
                                    href="#"
                                  > {d.model}
                                  </a>
                                </div>
                              </td>
                              <td className="text-right">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-sm">
                                  Manufacturer:
                               </span>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-sm">
                                  Brand :
                              </span>
                              </td>
                              <td className="text-left">
                                <span className="text-muted font-weight-bold d-block">
                                  {d.manufacturer}
                                </span>
                                <span className="text-muted font-weight-bold"> {d.brand} </span>
                              </td>
                              <td className="text-right">
                                {/* label-light-warning    label-light-primary  label-light-danger  label-light-success  */}
                                <span style={stylet} className={spantext}>
                                  {status}
                                </span>
                              </td>
                            </tr>
                          );
                        }) :
                          ' '
                        }


                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
          : <div>Loading</div>}
    </div>

  );

}
