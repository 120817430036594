/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { connect, shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import {  addActivity } from "../../../../../app/modules/UserActivity/_redux/UserActivityCrud";
export function AsideMenuList({ layoutProps }) {
  const [detailipv4, setdetailipv4] = useState(null);
  const [details, setdetails] = useState(null);
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  //const publicIp = require('react-public-ip');
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  function insertlog(url1, message1) {


    fetch('https://extreme-ip-lookup.com/json/?key=demo')
    .then( res => res.json())
    .then(response => {
      setdetails( response);
   })
   .catch((data, status) => {
    setdetails('Request failed');
   });

    let Data={};
    
    if (details=='Request failed'){
      Data = {
        isp:'Request failed',
        countrycode:'Request failed',
        region:'Request failed',
        city:'Request failed',
        country:'Request failed',
        message: message1,
        url: url1,
        action: 'view',
        ip: 'Request failed',
        action_type: 'user log'
      }
    }
    else{
    if (details!='Request failed'){
    Data = {
      //isp:details.isp,
      countrycode:details.countryCode,
      region:details.region,
      city:details.city,
      country:details.country,
      message: message1,
      url: url1,
      action: 'view',
      ip: details.query,
      action_type: 'user log'
   }
   }
    addActivity(Data);
  }
  }

 // console.log('is admin', user.role);
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {user.role == 'admin' ?
          <li
            className={`menu-item ${getMenuItemActive("/home", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/home" onClick={()=>insertlog('/home','view home page')}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
              </span>
              <span className="menu-text">Home</span>
            </NavLink>
          </li> : <div></div>}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboardtest</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {user.role == 'admin' || user.role == 'serviceman'?
          <li
            className={`menu-item ${getMenuItemActive("/dashboardsensor", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboardsensor" onClick={()=>insertlog('/dashboardsensor','view dashboard page')}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Diagnostics.svg")} />
              </span>
              <span className="menu-text">Dashboard </span>
            </NavLink>
          </li> : <div></div>}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/notification", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/react-bootstrap">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Box.svg")} />
            </span>
            <span className="menu-text">Test Comp</span>
          </NavLink>
        </li>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/sensors", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/sensors" onClick={()=>insertlog('/sensors','view sensors list page')} >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/CPU1.svg")} />
            </span>
            <span className="menu-text">Sensors</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/devices", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/devices" onClick={()=>insertlog('/devices','view devices list page')}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Box.svg")} />
            </span>
            <span className="menu-text">Devices</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/gateways", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/gateways" onClick={()=>insertlog('/gateway','view gateway list page')}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Router1.svg")} />
            </span>
            <span className="menu-text">Gateways</span>
          </NavLink>
        </li>


        <li
          className={`menu-item ${getMenuItemActive("/builder1", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" to="/builder1">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat1.svg")} />
            </span>
            <span className="menu-text">Chat</span>
          </div>
        </li>

        {user.role == 'admin' ?
          <li
            className={`menu-item ${getMenuItemActive("/builder2", false)}`}
            aria-haspopup="true"
          >
            <div className="menu-link">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Text.svg")} />
              </span>
              <span className="menu-text">Notes</span>
            </div>
          </li> : <div></div>}


        <li
          className={`menu-item ${getMenuItemActive("/builder3", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" to="/builder3">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Reports</span>
          </div>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/builder4", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" to="/builder4">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Alarm-clock.svg")} />
            </span>
            <span className="menu-text">Alarms</span>
          </div>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/e-commerce/customers"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/e-commerce/customers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Serviceman List</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/e-commerce/products"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/e-commerce/products">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")} />
            </span>
            <span className="menu-text">Seuppliers List</span>
          </NavLink>
        </li> */}
        {/*begin::1 Level*/}


        {user.role == 'admin' ?
          <li
            className={`menu-item ${getMenuItemActive("/users", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/users" onClick={()=>insertlog('/users','view users list page')}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Users</span>
            </NavLink>
          </li> : <div></div>}
        {/*end::1 Level*/}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
