import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { StatsWidget11 } from "./../_metronic/_partials/widgets";
import { TestpageComponent } from "./pages/TestpageComponent";
import { DashboardPage } from "./pages/DashboardPage";
import { DashboardSensorPage } from "./pages/DashboardSensorPage";
import { Device } from "./pages/DevicePage";
import { Sensors } from "./pages/SensorsPage";
import { SensorCharts } from "./pages/SensorChartsPage";
import { Gateway } from "./pages/GatewayPage";
import { GatewayDetail } from "./pages/GatewayDetailPage";
import { DeviceDetail } from "./pages/DeviceDetailPage";
import { Profile } from "./pages/ProfilePage";
import { Users } from "./pages/UsersPage";
import { UserActivity } from "./pages/UserActivityPage";
import { NotificationAlarm } from "./pages/NotificationP";
import { connect, shallowEqual, useSelector } from "react-redux";


const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
  const { user } = useSelector(state => state.auth);

  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
      {user.role == 'admin' ?
        
          //  Redirect from root URL to /dashboard. 
        
         // <Redirect exact from="/" to="/home" />
         <Redirect exact from="/" to="/sensors" />
 
        : 
           <Redirect exact from="/" to="/sensors" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/dashboardsensor" component={DashboardSensorPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/devices" component={Device} />
        <ContentRoute path="/sensors" component={Sensors} />
        <ContentRoute path="/gateways" component={Gateway} />
        <ContentRoute path="/profile" component={Profile} />
        <ContentRoute path="/users" component={Users} />
        <ContentRoute path="/gateway/:id" component={GatewayDetail} />
        <ContentRoute path="/device/:id" component={DeviceDetail} />
        <ContentRoute path="/sensor/:id/:axis/charts" component={SensorCharts} />
        <ContentRoute path="/UserActivity" component={UserActivity} />
        <ContentRoute path="/Notification" component={NotificationAlarm} />
        <ContentRoute path="/Widget" component={StatsWidget11} />
        <ContentRoute path="/test" component={TestpageComponent} />
        <ContentRoute path="/home" component={MyPage} />
        
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        {/* <Route path="/e-commerce" component={ECommercePage} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
