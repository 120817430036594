/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { connect, shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as sensor from "../_redux/sensorRedux";
import { get_Sensors, get_all_sensor, add_sensor, editSensor, deleteSensor } from "../_redux/sensorCrud";

import {
    Dialog, AppBar, Toolbar, IconButton, Typography, Button, List, ListItem,
    ListItemText, Divider, Slide, TextField,
    Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core'

function SensorFormDetail(props) {
    const { handleClose, currentData, updateMode } = props;
    const [accelerometer, setAccelerometer] = React.useState();
    const [filterselection, setFilterSelection] = React.useState();
    const [selftest, setSelftest] = React.useState();
    const [axis, setAxis] = React.useState();
    const [fifo, setFifo] = React.useState();
    const [lpf2_xl_en, setLpf2_XlEn] = React.useState();
    const [lpf2, setLpf1XlEn] = React.useState();
    const [sensor_name, setSensorName] = React.useState();
    const [sensor_password, setSensorPassword] = React.useState();
    const [wifi_name, setWifiName] = React.useState();
    const [wifi_password, setWifiPassword] = React.useState();
    const [wifi_name2, setWifiName2] = React.useState();
    const [wifi_password2, setWifiPassword2] = React.useState();
    const [wifi_name3, setWifiName3] = React.useState();
    const [wifi_password3, setWifiPassword3] = React.useState();
    const [wifi_name1, setWifiName1] = React.useState();
    const [wifi_password1, setWifiPassword1] = React.useState();
    const [rssi_threshold, setrssi_threshold] = React.useState();
    const [domain_name, setDomainName] = React.useState();
    const [get_data_link, setGetDataLink] = React.useState();
    const [timedelay, setTimeDelay] = React.useState();
    const [deepsleep, setDeepSleep] = React.useState();
    const [isdel, setIsDel] = React.useState();
    const [numberofdata, setNumberOfData] = React.useState();
    const [power, setPower] = React.useState();
    const [continuousdata, setContinuousData] = React.useState();
    const [update_url, setUpdateUrl] = React.useState();
    const [update_servername, setUpdateServername] = React.useState();
    const [initiateSensor, setInitiateSensor] = React.useState();
    const [update_available, setUpdateAvailable] = React.useState();
    const [gateway_id, setGatewayId] = React.useState();
    const [device_id, setDeviceId] = React.useState();
    const [user_id, setUserId] = React.useState();
    const [tempData, setTempData] = React.useState(currentData)
    const [tempretaure_caliber, settempretaure_caliber] = React.useState();
    const [battery_caliber, setbattery_caliber] = React.useState();
    const [battery_min, setbattery_min] = React.useState();
    const [tempretaure_max, settempretaure_max] = React.useState();
    const [vibration_max, setvibration_max] = React.useState();
    function onSubmit(params) {
        let Data = {
            title: sensor_name,
            img: 'dcard__image--flowers',
            accelerometer: accelerometer,
            filterselection: filterselection,
            selftest: selftest,
            axis: axis,
            fifo: fifo,
            lpf2_xl_en: lpf2_xl_en,
            lpf2: lpf2,
            sensor_name: sensor_name,
            sensor_password: sensor_password,
            wifi_name: wifi_name,
            wifi_password: wifi_password,
            domain_name: domain_name,
            wifi_name2: wifi_name2,//
            wifi_password2: wifi_password2,//
            wifi_name3: wifi_name3,//
            wifi_password3: wifi_password3,//
            wifi_name1: wifi_name1,//
            wifi_password1: wifi_password1,//    
            rssi_threshold: rssi_threshold,//             
            get_data_link: get_data_link,
            timedelay: timedelay,
            deepsleep: deepsleep,
            isdel: isdel,
            numberofdata: numberofdata,
            power: power,
            continuousdata: continuousdata,
            update_url: update_url,
            update_servername: update_servername,
            initiateSensor: initiateSensor,
            update_available: update_available,
            gateway_id: props.parent == 'gateway' ? props.parent_id : "",
            device_id: props.parent == 'device' ? props.parent_id : "",
            user_id: user_id,
            tempretaure_caliber: tempretaure_caliber,
            battery_caliber: battery_caliber,
            tempretaure_max: tempretaure_max,
            battery_min: battery_min,
            vibration_max: vibration_max
        }



        // UNCOMMENT THIS TO SEND DATA TO API 
        add_sensor(Data).then(res => {
            get_all_sensor(props.parent, props.parent_id).then(res => {
                props.setSensors(res.data)
            })
            handleClose();

        })


    }

    function onDelete(params) {

        deleteSensor(tempData.id).then(res => {
            get_all_sensor(props.parent, props.parent_id).then(res => {
                props.setSensors(res.data)
                handleClose();

            })
        })
    }

    function onSubmitEdit(params) {



        let Data = {
            id: tempData.id,
            title: tempData.sensor_name,
            img: 'dcard__image--flowers',
            accelerometer: accelerometer,
            filterselection: filterselection,
            selftest: selftest,
            axis: axis,
            fifo: fifo,
            lpf2_xl_en: lpf2_xl_en,
            lpf2: lpf2,
            sensor_name: sensor_name,
            sensor_password: sensor_password,
            wifi_name: wifi_name,
            wifi_password: wifi_password,
            wifi_name2: wifi_name2,//
            wifi_password2: wifi_password2,//
            wifi_name3: wifi_name3,//
            wifi_password3: wifi_password3,//
            wifi_name1: wifi_name1,//
            wifi_password1: wifi_password1,//    
            rssi_threshold: rssi_threshold,//             
            domain_name: domain_name,
            get_data_link: get_data_link,
            timedelay: timedelay,
            deepsleep: deepsleep,
            isdel: isdel,
            numberofdata: numberofdata,
            power: power,
            continuousdata: continuousdata,
            update_url: update_url,
            update_servername: update_servername,
            initiateSensor: initiateSensor,
            update_available: update_available,
            user_id: user_id


        }


        //console.log(Data)




        editSensor(Data, Data.id).then(res => {
            get_all_sensor(props.parent, props.parent_id).then(res => {
                props.setSensors(res.data);
                handleClose();

            })

        })


    }



    function handleChange(val) {
        var temp = tempData;

        switch (val.target.name) {
            case 'accelerometer':
                temp.accelerometer = (val.target.value)
                setAccelerometer(temp.accelerometer)
                break;
            case 'filterselection':
                temp.filterselection = (val.target.value)

                setFilterSelection(temp.filterselection)
                break;
            case 'selftest':
                temp.selftest = (val.target.value)

                setSelftest(temp.selftest)
                break;
            case 'axis':
                temp.axis = (val.target.value)

                setAxis(temp.axis)
                break;
            case 'fifo':
                temp.fifo = (val.target.value)

                setFifo(temp.fifo)
                break;
            case 'lpf2_xl_en':
                temp.lpf2_xl_en = (val.target.value)

                setLpf2_XlEn(temp.lpf2_xl_en)
                break;
            case 'lpf2':
                temp.lpf2 = (val.target.value)

                setLpf1XlEn(temp.lpf2)
                break;
            case 'sensor_name':
                temp.sensor_name = (val.target.value)

                setSensorName(temp.sensor_name)
                break;
            case 'sensor_password':
                temp.sensor_password = (val.target.value)

                setSensorPassword(temp.sensor_password)
                break;
            case 'wifi_name':
                temp.wifi_name = (val.target.value)

                setWifiName(temp.wifi_name)
                break;
            case 'wifi_name1':
                temp.wifi_name1 = (val.target.value)

                setWifiName1(temp.wifi_name1)
                break;
            case 'wifi_name2':
                temp.wifi_name2 = (val.target.value)

                setWifiName2(temp.wifi_name2)
                break;
            case 'wifi_name3':
                temp.wifi_name3 = (val.target.value)

                setWifiName3(temp.wifi_name3)
                break;
            case 'wifi_password':
                temp.wifi_password = (val.target.value)

                setWifiPassword(temp.wifi_password)
                break;
            case 'wifi_password1':
                temp.wifi_password1 = (val.target.value)

                setWifiPassword1(temp.wifi_password1)
                break;
            case 'wifi_password2':
                temp.wifi_password2 = (val.target.value)

                setWifiPassword2(temp.wifi_password2)
                break;
            case 'wifi_password3':
                temp.wifi_password3 = (val.target.value)

                setWifiPassword3(temp.wifi_password3)
                break;
            case 'rssi_threshold':
                temp.rssi_threshold = (val.target.value)

                setrssi_threshold(temp.rssi_threshold)
                break;
            case 'domain_name':
                temp.domain_name = (val.target.value)
                setDomainName(temp.domain_name)
                break;
            case 'get_data_link':
                temp.get_data_link = (val.target.value)

                setGetDataLink(temp.get_data_link)
                break;
            case 'timedelay':
                temp.timedelay = (val.target.value)
                setTimeDelay(temp.timedelay)
                break;
            case 'deepsleep':
                temp.deepsleep = (val.target.value)
                setDeepSleep(temp.deepsleep)
                break;
            case 'isdel':
                temp.isdel = (val.target.value)
                setIsDel(temp.isdel)
                break;
            case 'numberofdata':
                temp.numberofdata = (val.target.value)
                setNumberOfData(temp.numberofdata)
                break;
            case 'power':
                temp.power = (val.target.value)
                setPower(temp.power)
                break;

            case 'continuousdata':
                temp.continuousdata = (val.target.value)

                setContinuousData(temp.continuousdata)
                break;

            case 'update_url':
                temp.update_url = (val.target.value)

                setUpdateUrl(temp.update_url)
                break;

            case 'update_servername':
                temp.update_servername = (val.target.value)

                setUpdateServername(temp.update_servername)
                break;

            case 'initiateSensor':
                temp.initiateSensor = (val.target.value)

                setInitiateSensor(temp.initiateSensor)
                break;
            case 'update_available':
                temp.update_available = (val.target.value)

                setUpdateAvailable(temp.update_available)
                break;
            case 'gateway_id':
                temp.gateway_id = (val.target.value)

                setGatewayId(temp.gateway_id)
                break;
            case 'device_id':
                temp.device_id = (val.target.value)

                setDeviceId(temp.device_id)
                break;
            case 'user_id':
                temp.user_id = (val.target.value)

                setUserId(temp.user_id)
                break;
            default:
                break;
        }

    }

    return (
        <>



            <List>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem  >


                    <InputLabel style={{ width: '10%' }}>Sensor Name</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="sensor_name"
                        id="outlined-helperText"
                        //label="Sensor Name"
                        variant="outlined"
                        defaultValue="Success"
                        required
                        size="max"
                        value={tempData.sensor_name}
                        style={{ width: '25%' }}
                        disabled="true"
                    />
                    <InputLabel style={{ width: '10%' }}>Sensor Password</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="sensor_password"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.sensor_password}
                        style={{ width: '25%' }}
                        disabled="true"
                    />
                    <InputLabel style={{ width: '15%' }}>Temperature adjustment</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="tempretaure_caliber"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.tempretaure_caliber}
                        style={{ width: '15%' }}
                        type="number"
                        disabled="true"
                    />
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '15%' }}>Temperature adjustment</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="tempretaure_caliber"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.tempretaure_caliber}
                        style={{ width: '15%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '15%' }}>Battery adjustment</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="battery_caliber"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.battery_caliber}
                        style={{ width: '15%' }}
                        type="number"
                    />
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '20%' }}>Battery MIN (Alarm)</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="battery_min"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.battery_min}
                        style={{ width: '20%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '20%' }}>Vibration MAX (G))</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="vibration_max"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.vibration_max}
                        style={{ width: '20%' }}
                        type="number"
                    />
                    <InputLabel style={{ width: '20%' }}>Tempretaure MAX (C)</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="tempretaure_max"
                        id="outlined-helperText"
                        //label="Sensor Password"
                        variant="outlined"
                        value={tempData.tempretaure_max}
                        style={{ width: '20%' }}
                        type="number"
                    />

                </ListItem>

                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Wifi Name</InputLabel>
                    <TextField
                        onChange={handleChange}
                        disabled="true"
                        name="wifi_name"
                        id="outlined-helperText"
                        // label="Wifi Name"
                        variant="outlined"
                        value={tempData.wifi_name}
                        style={{ width: '40%' }}
                    />

                    <InputLabel style={{ width: '10%' }}>Wifi Password</InputLabel>
                    <TextField
                        onChange={handleChange}
                        disabled="true"
                        name="wifi_password"
                        id="outlined-helperText"
                        label="Wifi Password"
                        variant="outlined"
                        value={tempData.wifi_password}
                        style={{ width: '40%' }}

                    />
                </ListItem>
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Wifi Name No.2</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_name1"
                        id="outlined-helperText"
                        // label="Wifi Name"
                        variant="outlined"
                        value={tempData.wifi_name1}
                        style={{ width: '40%' }}
                    />

                    <InputLabel style={{ width: '10%' }}>Wifi Password No.2</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_password1"
                        id="outlined-helperText"
                        //  label="Wifi Password"
                        variant="outlined"
                        value={tempData.wifi_password1}
                        style={{ width: '40%' }}

                    />
                </ListItem>
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Wifi Name No.3</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_name2"
                        id="outlined-helperText"
                        // label="Wifi Name"
                        variant="outlined"
                        value={tempData.wifi_name2}
                        style={{ width: '40%' }}
                    />

                    <InputLabel style={{ width: '10%' }}>Wifi Password No.3</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_password2"
                        id="outlined-helperText"
                        //  label="Wifi Password"
                        variant="outlined"
                        value={tempData.wifi_password2}
                        style={{ width: '40%' }}

                    />
                </ListItem>
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Wifi Name No.4</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_name3"
                        id="outlined-helperText"
                        // label="Wifi Name"
                        variant="outlined"
                        value={tempData.wifi_name3}
                        style={{ width: '40%' }}
                    />

                    <InputLabel style={{ width: '10%' }}>Wifi Password No.4</InputLabel>
                    <TextField
                        onChange={handleChange}

                        name="wifi_password3"
                        id="outlined-helperText"
                        //   label="Wifi Password"
                        variant="outlined"
                        value={tempData.wifi_password3}
                        style={{ width: '40%' }}

                    />
                </ListItem>

                <ListItem>
                    <InputLabel style={{ width: '10%' }}>Domain Name</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="domain_name"
                        id="outlined-helperText"
                        label="Domain Name"
                        variant="outlined"
                        value={tempData.domain_name}
                        style={{ width: '40%' }}
                        disabled="true"
                    />
                    <InputLabel style={{ width: '10%' }}>Get Data Link</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="get_data_link"
                        id="outlined-helperText"
                        label="Get Data Link"
                        variant="outlined"
                        value={tempData.get_data_link}
                        style={{ width: '40%' }}
                        disabled="true"
                    />
                </ListItem>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem  >
                    <InputLabel style={{ width: '10%' }}>Update Available</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >
                        <Select
                            native
                            value={tempData.update_available}
                            style={{ width: '20%' }}
                            onChange={handleChange}
                            disabled="true"
                            inputProps={{
                                name: 'update_available',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}> No</option>
                            <option value={1}> Yes</option>
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '10%' }}>Update Domain</InputLabel>
                    <TextField
                        onChange={handleChange}
                        disabled="true"
                        name="update_servername"
                        id="outlined-helperText"
                        label="Update Domain"
                        variant="outlined"
                        value={tempData.update_servername}
                        style={{ width: '40%' }}

                    />
                    <InputLabel style={{ width: '10%' }}>Update Link</InputLabel>
                    <TextField
                        disabled="true"
                        onChange={handleChange}
                        name="update_url"
                        id="outlined-helperText"
                        label="Update Link"
                        variant="outlined"
                        value={tempData.update_url}
                        style={{ width: '40%', }}

                    />
                </ListItem>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <ListItem>

                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Initiate Sensor</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >
                        <Select
                            native
                            style={{ width: '20%' }}
                            value={tempData.initiateSensor}
                            disabled="true"

                            onChange={handleChange}

                            inputProps={{
                                name: 'initiateSensor',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Run Deep Sleep</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >
                        <Select
                            disabled="true"

                            value={tempData.deepsleep}
                            native
                            style={{ width: '20%' }}
                            onChange={handleChange}

                            inputProps={{
                                name: 'deepsleep',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </Select>
                    </FormControl>

                </ListItem>

                <ListItem>
                    <InputLabel style={{ width: '10%' }}>Power</InputLabel>
                    <TextField
                        onChange={handleChange}
                        name="power"
                        disabled="true"

                        id="outlined-helperText"
                        label="Power"
                        variant="outlined"
                        value={initiateSensor == 1 ? 100 : tempData.power}
                        style={{ width: '40%' }}

                    />
                    <InputLabel style={{ width: '10%' }}>Time Delay</InputLabel>
                    <TextField
                        onChange={handleChange}
                        disabled="true"

                        name="timedelay"
                        id="outlined-helperText"
                        label="Time Delay"
                        variant="outlined"
                        value={tempData.timedelay}
                        style={{ width: '40%' }}
                    />
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Continuous Data</InputLabel>
                    <FormControl style={{ width: '50%' }} variant="outlined" >
                        <Select
                            value={tempData.continuousdata}
                            disabled="true"

                            native
                            style={{ width: '20%' }}
                            onChange={handleChange}

                            inputProps={{
                                name: 'continuousdata',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </Select>
                    </FormControl>
                </ListItem>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem>
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Self Test</InputLabel>
                    <FormControl style={{ width: '20%' }} variant="outlined" >
                        <Select
                            disabled="true"

                            value={tempData.selftest}

                            onChange={handleChange}

                            native
                            style={{ width: '100%' }}

                            //onChange={handleChange}

                            inputProps={{
                                name: 'selftest',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={1}>Normal mode</option>
                            <option value={2}>Positive sign self-test</option>
                            <option value={3}>Negative sign self-test</option>

                        </Select>
                    </FormControl>

                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '11%' }} htmlFor="outlined-age-native-simple">FIFO Mode Selection</InputLabel>
                    <FormControl style={{ width: '39%' }} variant="outlined" >

                        <Select
                            value={tempData.fifo}
                            disabled="true"

                            onChange={handleChange}

                            native
                            style={{ width: '100٪' }}

                            //onChange={handleChange}
                            label="FIFO mode selection"
                            inputProps={{
                                name: 'fifo',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={1}>Bypass mode</option>
                            <option value={2}>FIFO mode</option>
                            <option value={3}>Continuous-to-FIFO mode</option>
                            <option value={4}>Bypass-to-continuous mode</option>
                            <option value={5}>Continuous mode</option>
                            <option value={6}>Bypass-to-FIFO mode</option>


                        </Select>
                    </FormControl>
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Number Of Data</InputLabel>
                    <TextField
                        onChange={handleChange}
                        disabled="true"

                        name="numberofdata"
                        id="outlined-helperText"
                        label="Number Of Data"
                        type="number"
                        variant="outlined"
                        value={tempData.numberofdata}
                        style={{ width: '40%' }}
                    />

                </ListItem>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem >
                    <InputLabel style={{ width: '10%' }} htmlFor="outlined-age-native-simple">Filter Selection</InputLabel>
                    <FormControl style={{ width: '20%' }} variant="outlined" >

                        <Select
                            native
                            style={{ width: '100%' }}
                            value={tempData.filterselection}
                            disabled="true"

                            onChange={handleChange}

                            inputProps={{
                                name: 'filterselection',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>Low Pass</option>
                            <option value={1}>High Pass</option>
                        </Select>
                    </FormControl>



                </ListItem>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <ListItem >
                    <InputLabel style={{ width: '22%' }} htmlFor="outlined-age-native-simple">Lowpass Filter Enable</InputLabel>
                    <FormControl style={{ width: '10%' }} variant="outlined" >

                        <Select
                            native
                            style={{ width: '100%' }}
                            value={tempData.lpf2_xl_en}
                            disabled="true"
                            onChange={handleChange}

                            inputProps={{
                                name: 'lpf2_xl_en',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </Select>
                    </FormControl>
                </ListItem >
                <ListItem>
                    <InputLabel style={{ width: '22%' }} htmlFor="outlined-age-native-simple">Accelerometer Full-scale Selection</InputLabel>
                    <FormControl style={{ width: '10%' }} variant="outlined" >

                        <Select
                            value={tempData.lpf2}
                            disabled="true"


                            native
                            style={{ width: '100%' }}
                            onChange={handleChange}

                            inputProps={{
                                name: 'lpf2',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>2G</option>
                            <option value={1}>4G</option>
                            <option value={2}>8G</option>
                            <option value={3}>16G</option>

                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <InputLabel style={{ width: '22%' }} htmlFor="outlined-age-native-simple">Axis Selection</InputLabel>
                    <FormControl style={{ width: '10%' }} variant="outlined" >

                        <Select
                            value={tempData.axis}
                            disabled="true"

                            onChange={handleChange}

                            native
                            style={{ width: '100%' }}

                            //onChange={handleChange}

                            inputProps={{
                                name: 'axis',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>3 axes (XYZ)</option>
                            <option value={1}>X-axis</option>
                            <option value={2}>Y-axis</option>
                            <option value={3}>Z-axis</option>


                        </Select>
                    </FormControl>
                </ListItem >
                <ListItem>
                    <InputLabel style={{ width: '22%' }} htmlFor="outlined-age-native-simple">Accelerometer Bandwidth Configurations</InputLabel>
                    <FormControl style={{ width: '10%' }} variant="outlined" >

                        <Select
                            value={tempData.accelerometer}
                            disabled="true"

                            onChange={handleChange}

                            native
                            style={{ width: '100%' }}

                            //onChange={handleChange}

                            inputProps={{
                                name: 'accelerometer',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={1}>ODR/4</option>
                            <option value={2}>ODR/10</option>
                            <option value={3}>ODR/20</option>
                            <option value={4}>ODR/45</option>
                            <option value={5}>ODR/100</option>
                            <option value={6}>ODR/200</option>
                            <option value={7}>ODR/400</option>
                            <option value={8}>ODR/800</option>


                        </Select>
                    </FormControl>
                </ListItem>




            </List>
        </>
    );
}
export default injectIntl(connect(null, sensor.actions)(SensorFormDetail));
