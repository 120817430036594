/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import {
    Button,
    Card,
    ListGroup,
    ListGroupItem,
    Nav,
    CardGroup,
    CardDeck,
    CardColumns
  } from "react-bootstrap";

import { FormattedMessage, injectIntl } from "react-intl";
import * as sensor from "../_redux/sensorsRedux";
import { prepareDataForValidation } from "formik";
var Axisspantext,spantext, status,stylet,  styletr;

function SensorTableRow(props) {
    const { showDetial } = props;
    
    Axisspantext = "label label-lg label-light-primary label-inline"
    if (props.predict_health_value >= 70) {
      spantext = "label label-lg  label-inline"
      stylet = {
        color: 'green',
        backgroundColor: 'limegreen'
      }
      status = "Good"
    }
    else {
      if (props.predict_health_value < 70 && props.predict_health_value >= 50) {
        spantext = "label label-lg label-light-warning label-inline"
        stylet = {
          // color: 'orange',
          // backgroundColor: '#ffeecc'                  
        }
        status = "Warning"
      }
      else {
        if (props.predict_health_value < 50) {
          spantext = "label label-lg label-light-danger label-inline"
          stylet = {
            // color: 'orange',
            // backgroundColor: '#ffeecc'                  
          }
          status = "Critical"
        }
    }}
    return (
        <>
        

        
            <tr>

                <td className="pl-0">
                    <div className="symbol symbol-30 symbol-light mr-1">
                        <span className="symbol-label">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Devices/Server.svg")}
                                className="h-30 align-self-center"
                            ></SVG>
                        </span>
                    </div>
                </td>
                <td className="pl-0">
                    <a
                        className="text-dark  font-weight-bold"
                        href="#"
                    >{props.device_name}
                    </a>
                </td>                
                <td className="pl-0">
                    <a
                        className="text-dark  font-weight-bold "
                        href="#"
                    >{props.id}
                    </a>
                </td>
                <td className="pl-0">
                    <a
                        className="text-dark  font-weight-bold"
                        href="#"
                    >{props.sensor_name}
                    </a>
                </td>
                <td className="pl-0">
                    <a
                        className="text-dark font-weight-bold text-hover-primary"
                        href="#"
                    >{props.macaddress}
                    </a>
                </td>
                <td className="pl-0">
                    <a
                        className="text-dark font-weight-bold text-hover-primary"
                        href="#"
                    >{props.timedelay}
                    </a>
                </td>
                <td className="pl-0">
                    <a
                        className="text-dark font-weight-bold text-hover-primary"
                        href="#"
                    >{props.initiateSensor}
                    </a>
                </td>
                <td className="pl-0">
                    <a
                        className="text-dark font-weight-bold text-hover-primary"
                        href="#"
                    >{props.linkedtosensor}
                    </a>
                </td>
                <td className="pl-0">
                    <button type="button" class="btn btn-outline-success">
                        <NavLink to={`/sensor/${props.id}/${'x'}/charts`}>
                            <span >X-Axis</span>
                        </NavLink>
                    </button>
                </td>
                <td className="pl-0">

                    <button type="button" class="btn btn-outline-success">
                        <NavLink to={`/sensor/${props.id}/${'y'}/charts`}>
                            <span >Y-Axis</span>
                        </NavLink>
                    </button>
                </td>
                <td className="pl-0">
                    <button type="button" class="btn btn-outline-success">
                        <NavLink to={`/sensor/${props.id}/${'z'}/charts`}>
                            <span >Z-Axis</span>
                        </NavLink>
                    </button>
                </td>
                <td className="pl-0">
                    <button type="button" onClick={showDetial} class="btn btn-outline-info">Details</button>
                </td>
                <td className="pl-0">

                    <span style={stylet} className={spantext}>
                      {status}
                    </span>
                </td>
            </tr>

        </>
    );
}
export default injectIntl(connect(null, sensor.actions)(SensorTableRow));
