import axios from "axios";
import {ME_URL} from "../../../../ServiceUrls";
import {EDIT_PROFILE_URL} from "../../../../ServiceUrls";
export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
//PUT END POINTS HEARE




export function editProfile(data) {
  return axios.put(EDIT_PROFILE_URL, data);

}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}




