/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, useRef } from "react";
import React, { useEffect, useState, FC } from "react";
import SVG from "react-inlinesvg";

import { connect, shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as sensor from "../_redux/sensorsRedux";
import { get_sensor_charts } from "../_redux/sensorsCrud";
import { get_sensor_fft_vibration } from "../_redux/sensorsCrud";
import WifiIndicator, { SignalStrength } from 'react-wifi-indicator';
import { DBMToSignalStrength } from 'react-wifi-indicator';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import SensorCard from "./SensorCard";
import HighchartsReact from 'highcharts-react-official';
//import Highcharts from 'highcharts';
import * as Highcharts from 'highcharts';

import Notifications from "../../Notification/pages/Notification1js";
//import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog, Toolbar, IconButton, Typography, Button, List, ListItem,
    ListItemText, Divider, Slide, TextField, Radio, RadioGroup, FormLabel,
    Select, MenuItem, FormControl, InputLabel, Card, Checkbox, FormControlLabel
} from '@material-ui/core'

import { Close, ContactsOutlined } from '@material-ui/icons'
import $ from 'jquery';
import SensorForm from "./SensorForm";
import { isNull, lowerCase } from "lodash";
import { setISODay } from "date-fns";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../../_metronic/layout";
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
//require ('highcharts/modules/full-screen')(Highcharts);
//var sensorName = '';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function SensorChartsPage(props) {
    const chart = useRef();
    const [battery, setbattery] = React.useState(0);
    const [health, sethealth] = React.useState(91.07);
    const [signal, setsignal] = React.useState(0);
    const [temp, settemp] = React.useState(0);
    const [Acceleration, setAcceleration] = React.useState(0);
    const [velocity, setvelocity] = React.useState(0);
    //console.log("props1 = ", props);
    const [packetdate, setpacketdate] = React.useState();
    const [packetdiagnostics, setpacketdiagnostics] = React.useState();
    
    // const [SensorFFTsVelocity, setSensorFFTsVelocity] = React.useState();
    const [sensorName, setsensorName] = React.useState();
    const [sensorid, setsensorid] = React.useState();
    const [Tempretaure_Calibre, SetTempretaure_Calibre] = React.useState(0);
    const [BatteryC, setBatteryC] = React.useState();

    //  const [Battery_Calibre, SetBattery_Calibre] = React.useState(0);
    const [isFirst, setIsFirst] = React.useState(true);
    const [isLast, setIsLast] = React.useState(false);
    const [DeviceOnchecked, setDeviceOnchecked] = React.useState(false);
    const [DeviceOffchecked, setDeviceOffchecked] = React.useState(false);
    const [DeviceBothchecked, setDeviceBothchecked] = React.useState(true);
    const [device, setDevice] = React.useState();

    const [Velocitychecked, setVelocitychecked] = React.useState(false);
    const [Accelerationchecked, setAccelerationchecked] = React.useState(true);
    const [Xchecked, setXchecked] = React.useState(false);
    const [Ychecked, setYchecked] = React.useState(false);
    const [Zchecked, setZchecked] = React.useState(false);
    const [axis, setaxis] = React.useState();

    const [currentData, setCurrentData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [seriesPredictFailure, setSeriesPredictFailure] = React.useState([]);
    if (isFirst) {
        props.setSensorFFTs();
        setaxis(props.axis);
        if (props.axis == 'x')
            setXchecked(true);
        else
            setXchecked(false);

        if (props.axis == 'y')
            setYchecked(true);
        else
            setYchecked(false);

        if (props.axis == 'z')
            setZchecked(true);
        else
            setZchecked(false);
    }
    var FFTChartY = [];
    var FFTChartX = [];

    var historicalChartmax = [];
    var historicalTemprature = [];
    var historicalBattery = [];
    var historicalChartmin = [];
    var historicalChartrms = [];
    var lastdatachart = [];

    var predictFailureChart = [];

    var dataJanuary = [];
    var dataFebruary = [];
    var dataMarch = [];
    var dataApril = [];
    var dataMay = [];
    var dataJune = [];
    var dataJuly = [];
    var dataAugust = [];
    var dataSeptember = [];
    var dataOctober = [];
    var dataNovember = [];
    var dataDecember = [];
    var xvaluemax = [];
    var xvaluemin = [];
    var xvaluerms = [];
    var packetid = [];

    var exportdata = {
        min: [],
        max: [],
        rms: [],
        time: [],
        status:[],
        battery : [],
        temperature :[],
        diagnosis_resultstring:[]
    };
    var Caption = "";
    var CaptionSensor = "";
    var CaptionAxis = "";
    var CaptionDate = "";
    var CaptionSensorid = "";
    var CaptionDiagnostics ="";
    const uiService = useHtmlClassService();
    const downloadCSV = () => {
        if (chart && chart.current && chart.current.chart) {
            chart.current.chart.downloadCSV();
        }

    }
    const optionsFFT = {
        chart: {
            type: 'arearange',
            zoomType: 'x',
            scrollablePlotArea: {
                scrollPositionX: 1
            }
        },
        title: {
            text: 'FFT Chart' + Caption
        },
        xAxis: {

            visible: true,
            valueSuffix: 'HZ',
            categories: FFTChartX,

        },
        tooltip: {
            crosshairs: true,
            shared: true,

            // xDateFormat: '%A, %b %e'
        },
        series: [{
            name: 'FFT(HZ)',
            color: 'blue',
            type: 'line',
            data: FFTChartY // We will set the FFTChartY array here
        }]
    }


    const optionsHistorical = {
        chart: {
            type: 'arearange',
            zoomType: 'x',
            scrollablePlotArea: {
                scrollPositionX: 1
            }
        },

        title: {
            text: 'Historical Data'
        },
        xAxis: {
            type: 'datetime',
            categories: xvaluemin,

            accessibility: {
                //      rangeDescription: 'Range: Jan 1st 2017 to Dec 31 2017.'
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true,
 //           valueSuffix: 'G',
            // xDateFormat: '%A, %b %e'
        },
        series: [
            {
                name: 'RMS',
                color: 'blue',
                type: 'line',
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            UpdateFFT_VIBRATION(this.x, this.y)
                            //console.log('X: ' + this.x + ', Y: ' + this.y);
                            //call function passing this values as arguments
                        }
                    }
                },
                data: historicalChartrms
            },
            {
                name: 'MIN',
                color: 'red',
                type: 'line',
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            UpdateFFT_VIBRATION(this.x, this.y)
                            //console.log('X: ' + this.x + ', Y: ' + this.y);
                            //call function passing this values as arguments
                        }
                    }
                },
                data: historicalChartmin
            },
            {
                name: 'MAX',
                color: 'green',
                type: 'line',
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            UpdateFFT_VIBRATION(this.x, this.y)
                            //console.log('X: ' + this.x + ', Y: ' + this.y);
                            //call function passing this values as arguments
                        }
                    }
                },
                data: historicalChartmax
            }]
    }

    function getChartOptionBattery(layoutProps) {
        console.log(historicalBattery);
        const optionsBattery = {

            series: [{

                name: 'Battery',
                color: 'red',
                //    type: 'line',
                data: historicalBattery

            }],
            chart: {
                type: "area",
                zoomType: 'x',
                height: 80,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: "solid",
                opacity: 1
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 3,
                colors: [layoutProps.colorsThemeBaseInfo]
            },
            xaxis: {
                categories: xvaluemin,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    style: {
                        colors: layoutProps.colorsGrayGray500,
                        fontSize: "12px",
                        fontFamily: layoutProps.fontFamily
                    }
                },
                crosshairs: {
                    show: false,
                    position: "front",
                    stroke: {
                        color: layoutProps.colorsGrayGray300,
                        width: 1,
                        dashArray: 3
                    }
                },
                tooltip: {
                    enabled: true,
                    valueSuffix: ' C',
                    formatter: undefined,
                    offsetY: 0,
                    style: {
                        fontSize: "12px",
                        fontFamily: layoutProps.fontFamily
                    }
                }
            },
            yaxis: {
                labels: {
                    show: false,
                    style: {
                        colors: layoutProps.colorsGrayGray500,
                        fontSize: "12px",
                        fontFamily: layoutProps.fontFamily
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                },
                y: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            colors: [layoutProps.colorsThemeLightInfo],
            markers: {
                colors: [layoutProps.colorsThemeLightInfo],
                strokeColor: [layoutProps.colorsThemeBaseInfo],
                strokeWidth: 3
            }
        };
        return optionsBattery;
    }


    function getChartOptionTemp(layoutProps) {
        const optionsTemperature = {
            //         chart: {
            //             type: 'arearange',
            //             zoomType: 'x',
            //             scrollablePlotArea: {
            //                 scrollPositionX: 1
            //             }
            //         },

            //         title: {
            //             text: 'Temperature Data'
            //         },
            //         xAxis: {
            //             type: 'datetime',
            //             categories: xvaluemin,
            // visible :false,
            //             accessibility: {
            //                 //      rangeDescription: 'Range: Jan 1st 2017 to Dec 31 2017.'
            //             }
            //         },
            //         tooltip: {
            //             crosshairs: true,
            //             shared: true,
            //             valueSuffix: ' C',
            //             // xDateFormat: '%A, %b %e'
            //         },
            //         series: [{

            //             name: 'Temperature',
            //             color: 'red',
            //             type: 'line',
            //             data: historicalTemprature


            //         }]
            //     }
            //     const options = {
            series: [{

                name: 'Temperature',
                color: 'red',
                //    type: 'line',
                data: historicalTemprature


            }],
            chart: {
                type: "area",
                zoomType: 'x',
                height: 80,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: "solid",
                opacity: 1
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 3,
                colors: [layoutProps.colorsThemeBaseSuccess]
            },
            xaxis: {
                categories: xvaluemin,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    style: {
                        colors: layoutProps.colorsGrayGray500,
                        fontSize: "12px",
                        fontFamily: layoutProps.fontFamily
                    }
                },
                crosshairs: {
                    show: false,
                    position: "front",
                    stroke: {
                        color: layoutProps.colorsGrayGray300,
                        width: 1,
                        dashArray: 3
                    }
                },
                tooltip: {
                    //enabled: true,
                    //valueSuffix: ' C',
                    formatter: undefined,
                    offsetY: 0,
                    style: {
                        fontSize: "12px",
                        fontFamily: layoutProps.fontFamily
                    }
                }
            },
            yaxis: {
                labels: {
                    show: false,
                    style: {
                        colors: layoutProps.colorsGrayGray500,
                        fontSize: "12px",
                        fontFamily: layoutProps.fontFamily
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                },
                y: {
                    formatter: function (val) {
                        return val + " 'C";
                    }
                }
            },
            colors: [layoutProps.colorsThemeLightSuccess],
            markers: {
                colors: [layoutProps.colorsThemeLightSuccess],
                strokeColor: [layoutProps.colorsThemeBaseSuccess],
                strokeWidth: 3
            }
        };
        return optionsTemperature;
    }

    // const optionsBattery = {
    //     chart: {
    //         type: 'arearange',
    //         zoomType: 'x',
    //         scrollablePlotArea: {
    //             scrollPositionX: 1
    //         }
    //     },

    //     title: {
    //         text: 'Battery Data'
    //     },

    //     tooltip: {
    //         crosshairs: true,
    //         shared: true,
    //         valueSuffix: ' C',
    //         // xDateFormat: '%A, %b %e'
    //     },
    //     xAxis: {
    //         type: 'datetime',
    //         categories: xvaluemin,

    //         accessibility: {
    //             //      rangeDescription: 'Range: Jan 1st 2017 to Dec 31 2017.'
    //         }
    //     },        
    //     series: [{

    //         name: 'Battery',
    //         color: 'red',
    //         type: 'line',
    //         data: historicalBattery


    //     }]
    // }
    const optionsLastPacketHistorical = {
        chart: {
            type: 'arearange',
            zoomType: 'x',
            scrollablePlotArea: {
                scrollPositionX: 1
            }
        },
        title: {
            text: 'Vibration'
        },
        series: [{
            name: sensorName,
            color: 'green',
            type: 'line',
            data: lastdatachart
        }]
    }


    const getAverage = (monthValues) => {
        var sum = 0;
        var avg = 0;
        console.log("jjj", monthValues)
        if (monthValues.length > 0) {
            console.log("sum", 1)
            for (let i = 0; i < monthValues.length; i++) {
                const value = monthValues[i];
                sum = sum + value;
            }
            avg = sum / monthValues.length;

        }


        if (monthValues == 0)
            return null;
        else
            return avg;
    };

    const optionsPredictFailure = {
        chart: {
            //type: 'areaspline'
            type: 'column'
        },
        title: {
          //  text: 'Device Failure percentage'
            text: 'Device health percentage'
        },
        legend: {

            verticalAlign: 'top',
            x: -500,
            y: 10,
            floating: true,
            borderWidth: 1,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
        },
        xAxis: {
            categories: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ]
        },
        yAxis: {
            title: {
 //               text: 'Failure percentage - 2022'
                text: 'Health percentage - 2022'
            }
            ,
            plotBands: [{ // visualize the weekend
                from: 40,
                to: 70,
                color: 'rgba(1, 1, 255, .2)'
            }, { // visualize the weekend
                from: 0,
                to: 40,
                color: 'rgba(255, 10, 13, .2)'
            }]
        },
        tooltip: {
            shared: true,
            valueSuffix: ' Percent'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.5
            }
        },
        series: [{
            type: 'line',
            id: 1,
            name: sensorName,

            data: [
                (dataJanuary), (dataFebruary),
                (dataMarch), (dataApril),
                (dataMay), (dataJune),
                (dataJuly), (dataAugust),
                (dataSeptember), (dataOctober),
                (dataNovember), (dataDecember)]
        }]
        //,
        // yAxis: {
        //     title: {
        //       text: 'Failure percentage'
        //     }
        //     ,
        //     plotBands: [{ // visualize the weekend
        //       from: 40,
        //       to: 70,
        //       color: '#ffcc66'//'rgba(1, 1, 255, .2)'
        //     }, { // visualize the weekend
        //       from: 70,
        //       to: 100,
        //       color: 'red'//'rgba(255, 10, 13, .2)'
        //     }, { // visualize the weekend
        //       from: 0,
        //       to: 50,
        //       color: '#90ed7d'//'rgba(255, 10, 13, .2)'
        //     }]
        //   },
        //   tooltip: {
        //     shared: true,
        //     valueSuffix: ' Percent'
        //   },
        //   credits: {
        //     enabled: false
        //   },
        //   plotOptions: {
        //     areaspline: {
        //       fillOpacity: 0.5
        //     }
        //   }
    }

    let { state } = useSelector(
        (state) => ({
            state: state.sensors
        }),
        shallowEqual
    );
    if (state == undefined) state = {};
    let history = useHistory();


    // در این قسمت بررسی میکنم که آیا صفحه تازه باز شده یا خیر 
    // اگه تازه باز شده باشه، اطلاعات رو از سرویس با کد زیر دریافت میکنیم

    if (isFirst) {
        setIsFirst(false);
        if (state.is_sensor_FFTs_loaded == 0 || state.is_sensor_packet_infos_loaded == 0 || isFirst) {
           // CaptionSensorid=props.id;
            // فراخوانی سرویس برای دریافت اطلاعات نمودارها
            get_sensor_charts(props.id, props.axis).then(res => {
                // Set FFT in state
                // sensorName=res.data.sensor_name;

                props.setSensorFFTs(res.data.FFT);

                //console.log("res.data.FFT_Velocity",res.data);

                props.setSensorPacketInfos(res.data.PacketInfo);

                // if (Velocitychecked){
                //  props.setSensorPacketInfosData(res.data.PacketInfoData_Velocity);
                // }
                // else
                var Bat;
                props.setSensorPacketInfosData(res.data.PacketInfoData);
                Bat = Number((res.data.PacketInfo[res.data.PacketInfo.length - 1].max).toFixed(3));
                if(res.data.PacketInfo[res.data.PacketInfo.length - 1].device_status==1){
                    setDevice('ON');
                }
                if(res.data.PacketInfo[res.data.PacketInfo.length - 1].device_status==0){
                    setDevice('OFF');
                }       
                
               // setDevice('OFF');
                console.log("device",res.data.PacketInfo[res.data.PacketInfo.length - 1].device_status);             
                setAcceleration(Bat);
                setvelocity(Bat * 58.5);
                settemp(res.data.PacketInfo[res.data.PacketInfo.length - 1].temperature);
                setsignal(res.data.PacketInfo[res.data.PacketInfo.length - 1].rssi);
                setbattery(res.data.PacketInfo[res.data.PacketInfo.length - 1].Battery);
                //  console.log("Packet", state.sensorPacketInfosDataVelocity);
                props.setSensorPredictFailures(res.data.PredictFailure);
                setsensorName(res.data.sensor_name);
                //  setsensorid(props.id) ;
                SetTempretaure_Calibre(res.data.tempretaure_caliber);
                // console.log('s',Tempretaure_Calibre);

                setBatteryC(res.data.Battery_caliber);

                //   console.log(BatteryC);



                // Bat = parseFloat(res.data.PacketInfo[res.data.PacketInfo.length - 1].Battery) + parseFloat(res.data.Battery_caliber);
                Bat = parseFloat(res.data.PacketInfo[res.data.PacketInfo.length - 1].Battery) + 0.45;
                Bat = Number((Bat).toFixed(3));
                setbattery(Bat);
                var Ndate1 = new Date(res.data.PacketInfo[res.data.PacketInfo.length - 1].packet_send_date);
                var str1 = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(Ndate1);
                setpacketdate(str1);
                console.log(res.data.PacketInfo[res.data.PacketInfo.length - 1].diagnosis_resultstring);
                setpacketdiagnostics(res.data.PacketInfo[res.data.PacketInfo.length - 1].diagnosis_resultstring);
            })
        }
    }
    // We will save the Y attribute of the FFT chart in this array. 
    // console.log("velocity",FFTChartYVelocity);
    //Checking if we have received the data from API
    if (state.sensorFFTs) {
        // This loops the FFT data and adds the Y attribute to the FFTChartY array
        // state.sensorFFTs.sort((a, b) => (a.x > b.x) ? 1 : -1);
        for (let i = 1; i < (state.sensorFFTs.length / 2); i++) {
        //for (let i = 0; i < (state.sensorFFTs.length / 2); i++) {
            const element = state.sensorFFTs[i * 2];
            if (Velocitychecked)
                FFTChartY.push(element * 58.5);
            else
                FFTChartY.push(element);
            element = state.sensorFFTs[i * 2 + 1];
            FFTChartX.push(Math.floor(element));
        }
    }

    if (state.sensorPacketInfosData && state.sensorPacketInfosData.length > 0) {

        var lastPacketData = state.sensorPacketInfosData;

        if (lastPacketData) {
            // if (Velocitychecked)
            //   setvibration(lastPacketData.max(58.5))
            // else
            //   setvibration(lastPacketData) 
            for (let i = 0; i < lastPacketData.length; i++) {
                const element = lastPacketData[i];
                if (Velocitychecked)
                    lastdatachart.push(element.value * 58.5);
                else
                    lastdatachart.push(element.value);
            }
        }
    }

    //console.log("state.sensorPacketInfos.length", state.sensorPacketInfos.length);

    if (state.sensorPacketInfos && state.sensorPacketInfos.length > 0) {

        //setbattery(state.sensorPacketInfos[state.sensorPacketInfos.length-1].Battery*25);
        for (let j = 0; j < state.sensorPacketInfos.length; j++) {
            var PacketData = state.sensorPacketInfos[j];
            const element = PacketData;
       
            if (DeviceOnchecked) {
                if (element.device_status == 1) {
                    var T;
                    var B;
                    T = parseFloat(element.temperature) + parseFloat(Tempretaure_Calibre);
                    if (Velocitychecked) {
                        historicalChartmin.push(element.min * 58.5);
                        historicalChartmax.push(element.max * 58.5);
                        historicalChartrms.push(element.rms * 58.5);
                    }
                    else {
                        historicalChartmin.push(element.min);
                        historicalChartrms.push(element.rms * 1);
                        historicalChartmax.push(element.max);
                    }
                    historicalTemprature.push(T);
                    B = parseFloat(element.Battery) + 0.45;
                    B = Number((B).toFixed(3));
                    historicalBattery.push(B);
                    var Ndate = new Date(element.packet_send_date);
                    var str = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(Ndate);
                    exportdata.max.push(element.max);
                    exportdata.min.push(element.min);
                    exportdata.time.push(str);
                    exportdata.diagnosis_resultstring.push(element.diagnosis_resultstring);
                    xvaluemin.push(str);
                    xvaluemax.push(str);
                    xvaluerms.push(str);
                    packetid.push(element.id);
                }
            }

            if (DeviceOffchecked) {
                if (element.device_status == 0) {
                    var T;
                    var B;
                    T = parseFloat(element.temperature) + parseFloat(Tempretaure_Calibre);
                    if (Velocitychecked) {
                        historicalChartmin.push(element.min * 58.5);
                        historicalChartmax.push(element.max * 58.5);
                        historicalChartrms.push(element.rms * 58.5);
                    }
                    else {
                        historicalChartmin.push(element.min);
                        historicalChartrms.push(element.rms * 1);
                        historicalChartmax.push(element.max);
                    }
                    historicalTemprature.push(T);
                    B = parseFloat(element.Battery) + 0.45;
                    B = Number((B).toFixed(3));
                    historicalBattery.push(B);
                    var Ndate = new Date(element.packet_send_date);
                    var str = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(Ndate);
                    exportdata.max.push(element.max);
                    exportdata.min.push(element.min);
                    exportdata.time.push(str);
                    exportdata.diagnosis_resultstring.push(element.diagnosis_resultstring);
                    xvaluemin.push(str);
                    xvaluemax.push(str);
                    xvaluerms.push(str);
                    packetid.push(element.id);
                }
            }
            if (DeviceBothchecked) {

                var T;
                var B;
                T = parseFloat(element.temperature) + parseFloat(Tempretaure_Calibre);
                if (Velocitychecked) {
                    historicalChartmin.push(element.min * 58.5);
                    historicalChartmax.push(element.max * 58.5);
                    historicalChartrms.push(element.rms * 58.5);
                }
                else {
                    historicalChartmin.push(element.min);
                    historicalChartrms.push(element.rms * 1);
                    historicalChartmax.push(element.max);
                }
                historicalTemprature.push(T);
                B = parseFloat(element.Battery) + 0.45;
                B = Number((B).toFixed(3));
                historicalBattery.push(B);
                var Ndate = new Date(element.packet_send_date);
                var str = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(Ndate);
                exportdata.max.push(element.max);
                if(element.device_status==1){
                    exportdata.status.push('ON');
                }
                if(element.device_status==0){
                    exportdata.status.push('OFF');
                } 
                exportdata.temperature.push(element.temperature);
                exportdata.time.push(str);
                exportdata.battery.push(element.Battery);
                exportdata.diagnosis_resultstring.push(element.diagnosis_resultstring);
                xvaluemin.push(str);
                xvaluemax.push(str);
                xvaluerms.push(str);
                packetid.push(element.id);

            }
        }

        if (!isLast)
            setIsLast(true);
    }
    CaptionSensor = sensorName ;
    CaptionDate = packetdate ;
    CaptionAxis = props.axis ;
    CaptionSensorid = props.id ;
    CaptionDiagnostics=packetdiagnostics;

    if (state.sensorPredictFailures && state.sensorPredictFailures.length > 0) {
        for (let i = 0; i < state.sensorPredictFailures.length; i++) {
            const element = state.sensorPredictFailures[i];
           // var diagnosis_resultcode=element.diagnosis_resultcode;
            var date = new Date(element.date);
            console.log("predict", element);
           // if (isNull(element.diagnosis_resultcode))
            if (date.getFullYear() == 2022)
                if ((date.getMonth() + 1) == 1) {
                    
                    dataJanuary.push(Number(element.value))
                } else
                    if ((date.getMonth() + 1) == 2) {
                        dataFebruary.push(Number(element.value))
                    } else
                        if ((date.getMonth() + 1) == 3) {
                            dataMarch.push(Number(element.value))
                        } else
                            if ((date.getMonth() + 1) == 4) {
                                dataApril.push(Number(element.value))
                            } else
                                if ((date.getMonth() + 1) == 5) {
                                    dataMay.push(Number(element.value))
                                } else
                                    if ((date.getMonth() + 1) == 6) {
                                        console.log("predict",date.getMonth() );
                                        dataJune.push(Number(element.value))
                                    } else
                                        if ((date.getMonth() + 1) == 7) {
                                            dataJuly.push(Number(element.value))
                                        } else
                                            if ((date.getMonth() + 1) == 8) {
                                                dataAugust.push(Number(element.value))

                                            } else
                                                if ((date.getMonth() + 1) == 9) {
                                                    dataSeptember.push(Number(element.value))
                                                } else
                                                    if ((date.getMonth() + 1) == 10) {
                                                        dataOctober.push(Number(element.value))
                                                    } else
                                                        if ((date.getMonth() + 1) == 11) {
                                                            dataNovember.push(Number(element.value))
                                                        } else
                                                            if ((date.getMonth() + 1) == 12) {
                                                                dataDecember.push(Number(element.value))
                                                            }
        }
    }




    const UpdateFFT_VIBRATION = (x, y) => {
        //console.log('vv',packetid[x]);
        // فراخوانی سرویس برای دریافت اطلاعات نمودارها
        get_sensor_fft_vibration(packetid[x], props.id, axis).then(res => {
            // if (state.SensorFFTsVelocity) {                 
            //     for (let i = 0; i < (state.SensorFFTsVelocity.length / 2); i++) {
            //         const element = state.SensorFFTsVelocity[i*2];
            //         FFTChartYVelocity.push(element);
            //         element = state.SensorFFTsVelocity[i*2+1];
            //         FFTChartXVelocity.push(Math.floor(element));
            //     }

            // }

            // Set FFT in state
            props.setSensorFFTs(res.data.FFT);
            // Set PacketInfo in state
            //console.log("sensorChart FFT res", res.data);
            props.setSensorPacketInfosData(res.data.PacketInfoData);
            ////console.log("Packet Infoes Data res", res.data.PacketInfoData);

            setsensorName(res.data.sensor_name);
        })


        // We will save the Y attribute of the FFT chart in this array. 

        //Checking if we have received the data from API
        // if (state.sensorFFTs) {
        //     // This loops the FFT data and adds the Y attribute to the FFTChartY array
        //     // state.sensorFFTs.sort((a, b) => (a.x > b.x) ? 1 : -1);
        //     for (let i = 0; i < (state.sensorFFTs.length / 2); i++) {
        //         const element = state.sensorFFTs[i * 2];
        //         FFTChartY.push(element);
        //         element = state.sensorFFTs[i * 2 + 1];
        //         FFTChartX.push(Math.floor(element));
        //     }

        // }
        // if (state.sensorPacketInfosData && state.sensorPacketInfosData.length > 0) {

        //     var lastPacketData = state.sensorPacketInfosData;
        //     if (lastPacketData) {
        //         for (let i = 0; i < lastPacketData.length; i++) {
        //             const element = lastPacketData[i];
        //             // if (Velocitychecked){
        //             //    element.value= lastPacketData[i].value*58.5;
        //             //    //console.log("",element.value);
        //             // }
        //             // else if (Accelerationchecked)
        //             //    element = lastPacketData[i];
        //             lastdatachart.push(element.value);
        //         }
        //     }
        // }
        CaptionSensor = sensorName ;
        CaptionDate = setpacketdate(exportdata.time[x]) ;
        CaptionAxis = props.axis ;
        CaptionSensorid = props.id ;
        //console.log(res.data.PacketInfo[res.data.PacketInfo.length - 1].diagnosis_resultstring);
        
        CaptionDiagnostics=setpacketdiagnostics(exportdata.diagnosis_resultstring[x]);
        setDevice(exportdata.status[x]);
        settemp(exportdata.temperature[x]);
        setAcceleration(exportdata.max[x]);
        setbattery(exportdata.battery[x]);
        // Caption = 'Sensor Name=' + sensorName + ' , Date = ' + setpacketdate(exportdata.time[x]) + ' , Axis = [' + axis + ']';
    }


    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.danger"
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.light.danger"
            ),
            colorsThemeBaseInfo: objectPath.get(
                uiService.config,
                "js.colors.theme.base.info"
            ),
            colorsThemeLightInfo: objectPath.get(
                uiService.config,
                "js.colors.theme.light.info"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {
        console.log('is message1', '1');
        const element = document.getElementById("kt_stats_widget_7_chart");
        const element1 = document.getElementById("kt_stats_widget_8_chart");
        if (!element) {
            return;
        }
        if (!element1) {
            return;
        }
        const options = getChartOptionTemp(layoutProps);
        const chart = new ApexCharts(element, options);
        chart.render();

        const options1 = getChartOptionBattery(layoutProps);
        const chart1 = new ApexCharts(element1, options1);
        chart1.render();

        return function cleanUp() {
            chart.destroy();
            chart1.destroy();
        };


    }, [isLast]);

    function checkedunit(id) {
        setVelocitychecked(!Velocitychecked);
        setAccelerationchecked(!Accelerationchecked);
        setXchecked(!Xchecked);
    }


    function checkeddevicestatus(id) {
        if (id == 1) {
            setDeviceOffchecked(false);
            setDeviceOnchecked(true);
            setDeviceBothchecked(false);
        }
        if (id == 2) {
            setDeviceOffchecked(true);
            setDeviceOnchecked(false);
            setDeviceBothchecked(false);
        }
        if (id == 3) {
            setDeviceOffchecked(false);
            setDeviceOnchecked(false);
            setDeviceBothchecked(true);
        }
    }

    function checkedaxis(id) {
        setXchecked(!Xchecked);
        setYchecked(!Ychecked);
        setZchecked(!Zchecked);
        if (id == 1) {

            setaxis('x');
        }
        if (id == 2) {

            setaxis('y');
        }
        if (id == 3) {

            setaxis('z');
        }
    }

    // const handleChange = (event) => {
    //     setaxis(event.target.value);
    //     console.log('x', event.target.value);
    //     return (
    //         <NavLink to={`/sensor/${473}/${'y'}/charts`}>
    //             <span >Y-Axis</span>
    //         </NavLink>
    //         // <Redirect 

    //         //   to={`/sensor/${props.id}/${axis}/charts`}
    //         // />

    //     )
    // };

    // In options of the chart, the "data" property will be the FFTChartY array

    // The "state.sensorPacketInfos" holds the "packet_info" table and "data" table records. 
    // I have printed the value below. look for the "Packet Info :::::::" in the //console.
    ////console.log('Packet Info :::::::', state.sensorPacketInfos);
    return (
        <>

            {/* <div className="row" >
                <List style={{ display: 'inline-flex' }} >
                    {sensorAxis.map(i => {
                        return (
                            <Card key={i.id} style={{ marginRight: 5, paddingLeft: 5 }}>
                                <FormControlLabel
                                    control={<Checkbox key={i.id} checked={i.checked} onChange={(e) => { checkedaxis(i.id, i.label) }} />}
                                    label={i.label}
                                />

                            </Card>

                        );
                    })}
                </List>
            </div> */}

            {
                (!isFirst) ?

                    <div>
                        {/* <div style={{ padding: "10px", "textAlign": "center", "background": "#1abc9c", "color": "white" }}>
                            <h4>{Caption}</h4>
                        </div> */}
                        <div className="row " >
                            {/* <div className="col-lg-6 ">
                            <Notifications sensorid={props.id}></Notifications>
                                </div> */}
                            <div className="col-lg-6 " >
                                {/* Header */}

                                {/* Body */}
                                <div className="card-body p-0 position-relative overflow-hidden bg-white">
                                    {/* Chart */}
                                    <div
                                        id="kt_mixed_widget_1_chart"
                                        className="card-rounded-bottom bg-danger"
                                        style={{ height: "110px" }}
                                    >
                                        
                                        <div style={{ padding: "10px", "textAlign": "center", "color": "white" }}>
                                            <h5 style={{textAlign:"left"}}>Sensor Name : {CaptionSensor}</h5> 
                                            <h5 style={{textAlign:"left"}}>Sensor ID   :{CaptionSensorid} ---- Axis        :{CaptionAxis}</h5>
                                            <h5 style={{textAlign:"left"}}>Packet Date :{CaptionDate}</h5>
                                            <h5 style={{textAlign:"left"}}>Diagnostics : {CaptionDiagnostics}</h5>
                                            
                                        </div>
                                    </div>

                                    {/* Stat */}
                                    <div className="card-spacer ">
                                        
                                        <div className="row m-0">
                                          
                                        <div className="col bg-light-secondary px-2 py-4 rounded-xl mr-5 mb-5">
                                                <span className="svg-icon svg-icon-3x svg-icon-secondary d-block my-2">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Devices/Router1.svg")}
                                                    ></SVG>
                                                </span>
                                                <a
                                                    href="#"
                                                    className="text-secondary font-weight-bold font-size-h7"
                                                >
                                                    Device : {device}
                                                </a>
                                            </div>                                        
                                            <div className="col bg-light-info px-2 py-4 rounded-xl mr-5 mb-5">
                                                <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Devices/Battery-full.svg")}
                                                    ></SVG>
                                                </span>
                                                <a
                                                    href="#"
                                                    className="text-info font-weight-bold font-size-h7"
                                                >
                                                    Battery : {battery}
                                                </a>
                                            </div>
                                            <div className="col bg-light-warning px-2 py-4 rounded-xl mr-5 mb-5">
                                                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Devices/Wi-fi.svg"
                                                        )}
                                                    ></SVG>
                                                </span>
                                                <a
                                                    href="#"
                                                    className="text-warning font-weight-bold font-size-h7 mt-2"
                                                >
                                                    Wifi : {DBMToSignalStrength(signal)}
                                                    {/* <h7 style={{color:'black'}}>{DBMToSignalStrength(signal)}</h7> */}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="col bg-light-danger px-2 py-4 rounded-xl  mr-5 mb-5">
                                                <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Weather/Temperature-full.svg")}
                                                    ></SVG>
                                                </span>
                                                <a
                                                    href="#"
                                                    className="text-danger font-weight-bold font-size-h7 mt-1"
                                                >
                                                    Tempretaure :{temp}
                                                </a>
                                            </div>
                                            <div className="col bg-light-success px-2 py-4 rounded-xl  mr-5 mb-5">
                                                <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Devices/Diagnostics.svg"
                                                        )}
                                                    ></SVG>
                                                </span>
                                                <a
                                                    href="#"
                                                    className="text-success font-weight-bold font-size-h7 mt-2"
                                                >
                                                    Acceleration : {Acceleration}
                                                </a>
                                            </div>
                                            <div className="col bg-light-primary px-2 py-4 rounded-xl  mr-5 mb-5">
                                                <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Devices/Diagnostics.svg"
                                                        )}
                                                    ></SVG>
                                                </span>
                                                <a
                                                    href="#"
                                                    className="text-primary font-weight-bold font-size-h7 mt-2"
                                                >
                                                    Velocity : {Acceleration*58.5}
                                                </a>
                                            </div>                                            
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            {/* {state.sensorFFTs != null? */}
                            <div className="col">
                                <div className="row-lg-4">

                                    <div className="card-body d-flex flex-column p-0 bg-white">
                                        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                                            <div className="d-flex flex-column mr-2">
                                                <a
                                                    href="#"
                                                    className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
                                                >
                                                    Historical Tempretaure
                                                </a>
                                                <span className="text-muted font-weight-bold mt-2">
                                                    Your Historical Device Tempretaure
                                                </span>
                                            </div>
                                            <span className="symbol symbol-light-danger symbol-55">
                                                <span className="symbol-label font-weight-bolder font-size-h7">
                                                    {temp} °C
                                                </span>
                                            </span>
                                        </div>
                                        <div
                                            id="kt_stats_widget_7_chart"
                                            className="card-rounded-bottom"
                                            style={{ height: "80px" }}
                                        >

                                        </div>
                                    </div>
                                </div>
                                <p></p>
                                <div className="row-lg-6">

                                    <div className="card-body d-flex flex-column p-0 bg-white">
                                        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                                            <div className="d-flex flex-column mr-2">
                                                <a
                                                    href="#"
                                                    className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
                                                >
                                                    Historical Battery
                                                </a>
                                                <span className="text-muted font-weight-bold mt-2">
                                                    Your Historical Sensor Battery Status
                                                </span>
                                            </div>
                                            <span className="symbol symbol-light-info symbol-55">
                                                <span className="symbol-label font-weight-bolder font-size-h7">
                                                    {battery} V
                                                </span>
                                            </span>
                                        </div>
                                        <div
                                            id="kt_stats_widget_8_chart"
                                            className="card-rounded-bottom"
                                            style={{ height: "80px" }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            {/*  :
                                 <p></p>
                             } */}
                        </div>
                        <div className="row" >

                            <List style={{ display: 'inline-flex' }} >
                                <Card key='1' style={{ marginRight: 5, paddingLeft: 5 }}>
                                    <FormControlLabel
                                        control={<Checkbox key='1' checked={Velocitychecked} onChange={(e) => { checkedunit(1) }} />}
                                        label='Velocity (m/s)'
                                    />
                                    <FormControlLabel
                                        control={<Checkbox key='2' checked={Accelerationchecked} onChange={(e) => { checkedunit(2) }} />}
                                        label='Acceleration (G)'
                                    />
                                </Card>
                                {/* <Card key='2' style={{ marginRight: 5, paddingLeft: 5 }}>
                                    <FormControl component="fieldset">
                                       
                                        <RadioGroup row aria-label="gender" name="gender1" value={axis} onChange={handleChange}>
                                            <FormControlLabel value="x" control={<Radio />} label="X" />
                                            <FormControlLabel value="y" control={<Radio />} label="Y" />
                                            <FormControlLabel value="z" control={<Radio />} label="Z" />
                                        </RadioGroup>
                                    </FormControl>      
                                    <NavLink to={`/sensor/${props.id}/${'y'}/charts`}>
                            <span >X-Axis</span>
                        </NavLink>                          
                                    </Card> */}
                            </List>
                        </div>
                        {/* <button onClick={(e) => exportToCSV(exportdata, 'fileName')}>Export</button> */}
                        {/* <div className="row" >
                            {state.sensorFFTs != null ?
                                // This is the chart
                                <div className="col-lg-2">
                                    <p></p>
                                    <Battery percentage={battery} batterytitle="Battery status"/>
                                    <p></p>
                                    <p></p>
                                    <Battery percentage={health} batterytitle="Health status" />
                                </div> :
                                <p>No Data</p>
                            }
                            {state.sensorFFTs != null ?
                                // This is the chart
                                <div className="col-lg-2">
                                    <WifiIndicator
                                       // strength={SignalStrength.EXCELLENT}
                                       strength={DBMToSignalStrength(signal)} 
                                    //    <WifiIndicator strength={SignalStrength.EXCELLENT} /> 
                                    //    <WifiIndicator strength={SignalStrength.GREAT} /> 
                                    //    <WifiIndicator strength={SignalStrength.OKAY} /> 
                                    //    <WifiIndicator strength={SignalStrength.WEAK} /> 
                                    //    <WifiIndicator strength={SignalStrength.UNUSABLE} /> 
                                    //    <WifiIndicator strength={SignalStrength.DISCONNECTED} />   
                                    // -30 dBm is the maximum achievable signal strength.
                                    // -42 dBm is an amazing, but realistic signal strength.
                                    // -90 dBm is unusable and approaching or drowning in the noise floor.
                                        alt={DBMToSignalStrength(signal)} 
                                        style={{
                                            height: 180,
                                            border: '2px solid cornflowerblue',
                                            borderRadius: 4,
                                            padding: 12,
                                        }}
                                    />
                                    <h3 >Signal = {signal} dbm</h3>
                                </div> :
                                <p></p>
                            }
                            {state.sensorFFTs != null ?
                                // This is the chart
                                <div className="col-lg-2">
                                    <Temp id="dial7" value={temp} title="Temp " />

                                </div> :
                                <p></p>
                            }
                            {state.sensorFFTs != null ?
                                // This is the chart
                                <div className="col-lg-3">
                                    <Speedometer
                                        id="dial5"
                                        value={Acceleration}
                                        title="Acceleration : ${value}"
                                        stop1="-16"
                                        stop2="-10"
                                        stop3="16"
                                        stop4="30"                                 
                                    />

                                </div> :
                                <p></p>
                            }
                            {state.sensorFFTs != null ?
                                // This is the chart
                                <div className="col-lg-3">
                                    <Speedometer
                                        id="dial5"
                                        value={velocity}
                                        title="Velocity : ${value}"
                                        stop1="-1"
                                        stop2="-0.586"
                                        stop3="1"
                                        stop4="2"                                 
                                          
                                    />

                                </div> :
                                <p></p>
                            }
                        </div>


                         */}
                        <div className="row" >
                            {state.sensorFFTs != null ?
                                // This is the chart
                                <div className="col-lg-6">
                                    <HighchartsReact
                                        // containerProps={{ style: { height: "220px" } }}
                                        highcharts={Highcharts}
                                        options={optionsFFT}
                                    />
                                </div> :
                                <p>No Data</p>
                            }
                            {state.sensorFFTs != null ?
                                // This is the chart
                                <div className="col-lg-6">
                                    <HighchartsReact
                                        // containerProps={{ style: { height: "220px" } }}
                                        ref={chart}
                                        highcharts={Highcharts}
                                        options={optionsLastPacketHistorical}
                                    />
                                </div> :
                                <p></p>
                            }
                        </div>
                        <div className="row" >

                            <List style={{ display: 'inline-flex' }} >
                                <Card key='1' style={{ marginRight: 5, paddingLeft: 5 }}>

                                        Device Status : 

                                    <FormControlLabel
                                        control={<Checkbox key='1' checked={DeviceOnchecked} onChange={(e) => { checkeddevicestatus(1) }} />}
                                        label='ON'
                                    />
                                    <FormControlLabel
                                        control={<Checkbox key='2' checked={DeviceOffchecked} onChange={(e) => { checkeddevicestatus(2) }} />}
                                        label='OFF'
                                    />
                                    <FormControlLabel
                                        control={<Checkbox key='3' checked={DeviceBothchecked} onChange={(e) => { checkeddevicestatus(3) }} />}
                                        label='BOTH'
                                    />
                                </Card>
                                {/* <Card key='2' style={{ marginRight: 5, paddingLeft: 5 }}>
                                    <FormControl component="fieldset">
                                       
                                        <RadioGroup row aria-label="gender" name="gender1" value={axis} onChange={handleChange}>
                                            <FormControlLabel value="x" control={<Radio />} label="X" />
                                            <FormControlLabel value="y" control={<Radio />} label="Y" />
                                            <FormControlLabel value="z" control={<Radio />} label="Z" />
                                        </RadioGroup>
                                    </FormControl>      
                                    <NavLink to={`/sensor/${props.id}/${'y'}/charts`}>
                            <span >X-Axis</span>
                        </NavLink>                          
                                    </Card> */}
                            </List>
                        </div>
                        <div className="row" >
                            {state.sensorFFTs != null ?

                                // This is the chart
                                <div className="col-lg-12">
                                    <HighchartsReact
                                        // containerProps={{ style: { height: "220px" } }}
                                        highcharts={Highcharts}
                                        options={optionsHistorical}
                                    />
                                </div> :
                                <p></p>
                            }
                        </div>
                        {/* <div className="row" >
                            {state.sensorFFTs != null ?

                                // This is the chart
                                <div className="col-lg-12">
                                    <HighchartsReact
                                        // containerProps={{ style: { height: "220px" } }}
                                        highcharts={Highcharts}
                                        options={optionsTemperature}
                                    />
                                </div> :
                                <p></p>
                            }
                        </div>
                        <div className="row" >
                            {state.sensorFFTs != null ?

                                // This is the chart
                                <div className="col-lg-12">
                                    <HighchartsReact
                                        // containerProps={{ style: { height: "220px" } }}
                                        highcharts={Highcharts}
                                        options={optionsBattery}
                                    />
                                </div> :
                                <p></p>
                            }
                        </div>                         */}
                        <div className="row" >
                            {state.sensorFFTs != null ?

                                // This is the chart
                                <div className="col-lg-12">
                                    <HighchartsReact
                                        // containerProps={{ style: { height: "220px" } }}
                                        allowChartUpdate={true}
                                        highcharts={Highcharts}
                                        options={optionsPredictFailure}
                                    />
                                </div> :
                                <p></p>
                            }
                        </div>

                    </div> : <div>Loading</div>}



        </>
    );
}
export default injectIntl(connect(null, sensor.actions)(SensorChartsPage));
