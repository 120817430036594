import React, { forwardRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";

import { makeStyles, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn"; import * as users from "../_redux/userRedux";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
    chart: {
        marginLeft: theme.spacing(2)
    }
}));
function UsersTable(props) {
    const classes = useStyles();




 // console.log('users 2= ', props.users);
    return (
        <React.Fragment>
            <div>
                <MaterialTable
                    icons={tableIcons}
                    title="Usres Information"
                    data={props.users}
                    columns={[
                        { title: "First Name", field: "first_name" },
                        { title: "Last Name", field: "last_name" },
                        { title: "Username", field: "name" },
                        { title: "Phone", field: "phone" },
                        { title: "Email", field: "email" },
                        { title: "Role", field: "roles[0].slug" },

                    ]}
                    // parentChildData={(row, rows) =>
                    //     rows.find(a => a.id === row.parentId)
                    // }
                    options={{
                      //  selection: true,
                        pageSize: 10
                    }}
                    detailPanel={rowData => {
                        return (
                        
                              <MaterialTable
                      title="Sensor Details Info"
                      data={props.users.sensores}
                      columns={[
                        { title: 'Name', field: 'sensor_name' },
                        { title: 'Surname', field: 'gateway_name' },
                        { title: 'Birth Year', field: 'wifi_name', type: 'numeric' },
                        {
                          title: 'Birth Place',
                          field: 'birthCity',
                          lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
                        },
                      ]}
                             width="100%"
                            height="315"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          />
                        )
                      }}
                // onSelectionChange={rows => {
                //     alert("Selected id is:  " + rows[0].id);
                // }}
                />
            </div>

        </React.Fragment>
    );
}

export default injectIntl(connect(null, users.actions)(UsersTable));
