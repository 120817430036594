import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./UserActivityCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  UserActivitiesLoaded: "[UserActivity] UserActivitiesLoaded  Action",
  AddUserActivity: "[UserActivity] AddUserActivity Action",
  HandleVar: "[UserActivity] HandleVar Action"


};

const initialState = {
  user: undefined,
  authToken: undefined,
  UserActivities: [],
  is_UserActivities_loaded: 0,
  message: '',
  url: '',
  action: '',
  ip: '',
  browser: '',
  location: '',
  os: '',
  postcode: '',
  country: '',
  city: '',
  action_type: '',
  action_date: ''

};

export const reducer = persistReducer(
  { storage, key: "v705-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionTypes.HandleVar: {

        switch (action.name) {
          case 'message':
            //console.log("name :::::", action.value)
            return {
              ...state,
              message: action.value,
            };
          case 'url':
            return {
              ...state,
              sensor_password: action.value,
            };

          case 'action':
            return {
              ...state,
              action: action.value,
            };

          case 'ip':
            return {
              ...state,
              ip: action.value,
            };

          case 'browser':
            return {
              ...state,
              browser: action.value,
            };

          case 'os':
            return {
              ...state,
              os: action.value,
            };
          case 'action_type':
            return {
              ...state,
              action_type: action.value,
            };
          case 'action_date':
            return {
              ...state,
              sensor_password: action.value,
            };
          case 'action_date':
            return {
              ...state,
              sensor_password: action.value,
            };
          case 'city':
            return {
              ...state,
              city: action.value,
            };
          case 'country':
            return {
              ...state,
              country: action.value,
            };
          case 'postcode':
            return {
              ...state,
              postcode: action.value,
            };
        }
      }

      case actionTypes.UserActivitiesLoaded: {
        return { ...state, is_UserActivities_loaded: 2, UserActivities: action.payload };
      }


      case actionTypes.AddUserActivity: {
        //console.log("payload :::", action.payload)

        return {
          ...state,
          UserActivities: initialState.UserActivities.concat(action.payload)
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {

  setUserActivities: (items) => ({ type: actionTypes.UserActivitiesLoaded, payload: items }),
  addNewUserActivity: (item) => ({ type: actionTypes.AddUserActivity, payload: item }),
  handleVar: (name, value) => ({ type: actionTypes.HandleVar, payload: { name, value } }),

};


