import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../layout";

import {Demo1Dashboard} from "../dashboardsensor/Demo1Dashboard";


export function Dashboardsensor() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            demo: objectPath.get(
                uiService.config,
                "demo"
            )};
    }, [uiService]);
    return <>
        
        {layoutProps.demo === 'demo2' && <Demo1Dashboard />}

    </>;
}
