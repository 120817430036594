import axios from "axios";
import {LOGIN_URL} from "../../../../ServiceUrls";
import {REGISTER_URL} from "../../../../ServiceUrls";
import {ME_URL} from "../../../../ServiceUrls";

export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";


export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, first_name, last_name, phone, company_name, name, password, role) {
  return axios.post(REGISTER_URL, { email, first_name, last_name, phone, company_name, name, password, role });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
