import { shallowEqual, useSelector } from "react-redux";

export default function setupAxios(axios, store) {

    axios.interceptors.request.use(
        config => {
            const {
                auth: { authToken }
            } = store.getState();

          //  console.log("authToken 1", authToken)
            if (authToken) {

                // console.log("authToken 2", authToken)
                config.headers.Authorization = `Bearer ${authToken}`;
            } else {

                const auth = localStorage.getItem('authToken');
            //    console.log("authToken 3", auth);
                if (auth && auth != '') {
                    config.headers.Authorization = `Bearer ${auth}`;
                }

            }

            return config;
        },
        err => Promise.reject(err)
    );
    axios.interceptors.response.use((response) => {
        // do something with the response data
      //  console.log('Response was received', response);
        return response;
    }, error => {
        // handle the response error
     //   console.log('Error Response was received', error.response.status);


        if (error.response.status == 401) {
            localStorage.setItem('authToken', 'unauthorized');
            window.location = '/logout';

        }
        return Promise.reject(error);
    });

}
