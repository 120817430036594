/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {getNoticationBySensorId,  get_all_Notification } from "../_redux/NotificationCrud";
import {
    Button,
    Card,
    ListGroup,
    ListGroupItem,
    Nav,
    CardGroup,
    CardDeck,
    CardColumns
} from "react-bootstrap";

import { FormattedMessage, injectIntl } from "react-intl";
import * as sensor from "../../Sensors/_redux/sensorsRedux";
import { prepareDataForValidation } from "formik";
import is from "date-fns/locale/is/index";
var Axisspantext, spantext, status, stylet, styletr;

function Notifications(props) {
    const [notif, setnotif] = React.useState();
    const [isfirst, setisfirst] = React.useState(true);
    var notificationlist = [];
   
    var $id=props.sensorid;

    // get_all_Notification().then(res => {


    //     console.log("Notifications",res.data);
    // })

    Axisspantext = "label label-lg label-light-primary label-inline"
    if (props.predict_health_value >= 70) {
        spantext = "label label-lg  label-inline"
        stylet = {
            color: 'green',
            backgroundColor: 'limegreen'
        }
        status = "Good"
    }
    else {
        if (props.predict_health_value < 70 && props.predict_health_value >= 50) {
            spantext = "label label-lg label-light-warning label-inline"
            stylet = {
                // color: 'orange',
                // backgroundColor: '#ffeecc'                  
            }
            status = "Warning"
        }
        else {
            if (props.predict_health_value < 50) {
                spantext = "label label-lg label-light-danger label-inline"
                stylet = {
                    // color: 'orange',
                    // backgroundColor: '#ffeecc'                  
                }
                status = "Critical"
            }
        }
    }

function list(){
    return(
    
    notificationlist.map(d => {
      
        <tr>
          <td className="pl-0 py-4">
            <div className="symbol symbol-40 symbol-light mr-1">
              <span className="symbol-label">
                <SVG
                  src={toAbsoluteUrl("/media/svg/misc/004-retweet.svg")}
                  className="h-40 align-self-center"
                ></SVG>
                {d.id}
              </span>
            </div>
          </td>
           </tr>
       
        })
    )
}


    useEffect(() => {
        if (isfirst) {
            getNoticationBySensorId($id).then(res => {
                
                notificationlist=res.data;
                setisfirst(false);
                console.log("Notifications1", notificationlist);
                console.log("setisfirst", isfirst);
            })
            
        }
  
    },[isfirst]);
    return (
        <>
            <div >
                {/* Header */}

                {/* Body */}
                <div className="card-body pt-0 bg-white">
                    <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                        <div className="d-flex flex-column mr-2">
                            <a
                                href="#"
                                className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
                            >
                                Recent Activities
                            </a>

                        </div>

                    </div>
                    <div className="timeline timeline-5 mt-3"> 
               
                    {!isfirst ? notificationlist.map((d) => {
                        <div className="timeline-item align-items-start">
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">
                        { d.desciption}
                        </div>

                        <div className="timeline-badge">
                            <i className="fa fa-genderless text-success icon-xl"></i>
                        </div>

                        <div className="timeline-content text-dark-50">
                            Outlines of the recent activities that happened last weekend
                        </div>
                    </div>
                                   }) :
                                   ' '
                                 }
                     
                    


                        {/* <div className="timeline-item align-items-start">
                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">
                                3 hr
                            </div>

                            <div className="timeline-badge">
                                <i className="fa fa-genderless text-danger icon-xl"></i>
                            </div>

                            <div className="timeline-content d-flex">
                                <span className="mr-4 font-weight-bolder text-dark-75">
                                    AEOL meeting with
                                </span>

                                <div className="d-flex align-items-start mt-n2">
                                    <a
                                        href="#"
                                        className="symbol symbol-35 symbol-light-success mr-2"
                                    >
                                        <span className="symbol-label">
                                            <SVG
                                                className="h-75 align-self-end"
                                                src={toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")}
                                            ></SVG>
                                        </span>
                                    </a>

                                    <a href="#" className="symbol symbol-35 symbol-light-success">
                                        <span className="symbol-label">
                                            <SVG
                                                className="h-75 align-self-end"
                                                src={toAbsoluteUrl("/media/svg/avatars/002-girl.svg")}
                                            ></SVG>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-item align-items-start">
                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">
                                14:37
                            </div>

                            <div className="timeline-badge">
                                <i className="fa fa-genderless text-info icon-xl"></i>
                            </div>

                            <div className="timeline-content font-weight-bolder text-dark-75">
                                Submit initial budget -
                                <a href="#" className="text-primary">
                                    USD 700
                                </a>
                            </div>
                        </div>

                        <div className="timeline-item align-items-start">
                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">
                                16:50
                            </div>

                            <div className="timeline-badge">
                                <i className="fa fa-genderless text-danger icon-xxl"></i>
                            </div>

                            <div className="timeline-content text-dark-50">
                                Stakeholder meeting
                            </div>
                        </div>

                        <div className="timeline-item align-items-start">
                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">
                                17:30
                            </div>

                            <div className="timeline-badge">
                                <i className="fa fa-genderless text-success icon-xl"></i>
                            </div>

                            <div className="timeline-content text-dark-50">
                                Project scoping & estimations with stakeholders
                            </div>
                        </div>

                        <div className="timeline-item align-items-start">
                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">
                                21:03
                            </div>

                            <div className="timeline-badge">
                                <i className="fa fa-genderless text-warning icon-xxl"></i>
                            </div>

                            <div className="timeline-content font-weight-bolder text-dark-75">
                                New order placed{" "}
                                <a href="#" className="text-primary">
                                    #XF-2356
                                </a>
                            </div>
                        </div>

                        <div className="timeline-item align-items-start">
                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">
                                21:07
                            </div>

                            <div className="timeline-badge">
                                <i className="fa fa-genderless text-danger icon-xl"></i>
                            </div>

                            <div className="timeline-content text-dark-50">
                                Company BBQ to celebrate the last quater achievements and goals.
                                Food and drinks provided
                            </div>
                        </div>

                        <div className="timeline-item align-items-start">
                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">20:30</div>
                            <div className="timeline-badge">
                                <i className="fa fa-genderless text-info icon-xl"></i>
                            </div>
                            <div className="timeline-content text-dark-50">
                                Marketing campaign planning with customer.
                            </div>
                        </div> */}

                    </div>
                    <div>
                        <a href='#' className='text-gray-800 fw-bolder text-hover-primary fs-6'>
                            More 
                           
                            {notificationlist != null ? list() :
' jjj'
}

                         
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
export default injectIntl(connect(null, Notification.actions)(Notifications));
