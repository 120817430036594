/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
    Button,
    Card,
    ListGroup,
    ListGroupItem,
    Nav,
    CardGroup,
    CardDeck,
    CardColumns
  } from "react-bootstrap";

import { FormattedMessage, injectIntl } from "react-intl";
import * as sensor from "../_redux/sensorsRedux";

function SensorCard(props) {
    const { showDetial } = props;

    return (
        <>
            <li className="dcards__item">
                <div className="dcard">
                   
                    <div className={`dcard__image  dcard__image--sensor`}></div>
                    <div className="dcard__content">
                        <div className="dcard__title">{props.title}</div>                      
                         <p className="dcard__text">
                            {props.description}
                        </p>
                        {/* <button className="lm-btn" onClick={showDetial} >Show Details</button> */}
                        <button onClick={showDetial} className="btn dcard__btn" >Show Details</button>                        
                        {/* <span style={{cursor:'pointer'}} onClick={showDetial} className="lm-btn">Show Details</span> */}
                        <NavLink  to={`/sensor/${props.id}/${'x'}/charts`}>
                            <span >X-Axis Charts</span>
                        </NavLink> 
                        <NavLink  to={`/sensor/${props.id}/${'y'}/charts`}>
                            <span >Y-Axis Charts</span>
                        </NavLink>                       
                        <NavLink  to={`/sensor/${props.id}/${'z'}/charts`}>
                            <span >Z-Axis Charts</span>
                        </NavLink>                       </div>
                </div>

                {/* <Card style={{ width: '108rem' }}>
                    <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
                    <Card.Body>
                        <Card.Title>{props.title}</Card.Title>
                        <Card.Text>
                        {props.description}
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Cras justo odio</ListGroupItem>
                        <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                        <ListGroupItem>Vestibulum at eros</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="#">Card Link</Card.Link>
                        <Card.Link href="#">Another Link</Card.Link>
                    </Card.Body>
                </Card>    */}

            </li>

        </>
    );
}
export default injectIntl(connect(null, sensor.actions)(SensorCard));
