/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { Link, Switch, Redirect } from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as UserActivity from "../_redux/UserActivityRedux";
import { get_UserActivities, get_all_UserActivities, addUserActivity, getUserActivityById } from "../_redux/UserActivityCrud";
import { useHistory } from "react-router-dom";

// import { makeStyles } from '@material-ui/core/styles';
import {
   Slide
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import $ from 'jquery';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function UserActivityPage(props) {

  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [currentData, setCurrentData] = React.useState([]);
  const [updateMode, setUpdateMode] = React.useState(false);



  const handleClickOpen = () => {
    setCurrentData([])
    setUpdateMode(false);
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
    setOpen(false);
  };

  const [collapsedHeader, setCollapsedHeader] = useState(true);
  let { state } = useSelector(
    (state) => ({
      state: state.UserActivity
    }),
    shallowEqual
  );
  if (state === undefined) state = {};
  let history = useHistory();


  if (state.is_UserActivities_loaded == 0) {


    get_all_UserActivities().then(res => {
      ////console.log("res", res);
      props.setUserActivities(res.data)
    })

  }


  console.log('props = ', props);
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <ul className="dcards">




          <li className="addLi">
            <div className="addNewDev">
              <h1 className="addDev" >
                Add New +
                </h1>
            </div>
          </li>
        </ul>
      </div>


    </>
  );
}
export default injectIntl(connect(null, UserActivity.actions)(UserActivityPage));
