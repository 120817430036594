import axios from "axios";
import {GET_USERS_URL} from "../../../../ServiceUrls";


export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
//PUT END POINTS HERE

export const ME_URL = "api/me";



export function get_all_users() {

  return axios.get(GET_USERS_URL);
}

